const initState = {
  checkoutSessionData: null,
  updateStripeData: null,
  billingInfo: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_STRIPE":
      // console.log(action.payload);
      return {
        ...state,
        updateStripeData: action.payload,
      };
    case "GET_STRIPE_BILLING":
      // console.log(action.payload);
      return {
        ...state,
        billingInfo: action.payload,
      };
    default:
      return state;
  }
};
