import instance from "../redux/actions/AxiosInstance/authenticated";

export const downloadFile = async (url, task_data) => {
  const formattedDateTime = convertMongooseDateTimeToDDMMYYYYTime(
    task_data.createdAt
  );

  let filename = `result-${task_data.task_name}-${formattedDateTime}.csv`;

  await instance
    .get(url)
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", filename);
      link.setAttribute("hidden", true);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => console.log(err));
};
export function convertMongooseDateTimeToDDMMYYYYTime(mongooseDateTime) {
  const date = new Date(mongooseDateTime);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}-${month}-${year}-${hours}:${minutes}`;
}
