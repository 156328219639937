import Swal from "sweetalert2";

export const successAlert = (options) => {
  Swal.fire({
    icon: "success",
    iconColor: "#31C48D",
    confirmButtonColor: "#1C64F2",
    ...options,
    customClass: {
      title: "!font-semibold !text-[#374151] !pt-[10px]",
      popup: "!font-['Inter'] !rounded-[12px]",
      ...options.customClass,
    },
  });
};

export const failAlert = (options) => {
  Swal.fire({
    icon: "error",
    iconColor: "#E02424",
    confirmButtonColor: "#1C64F2",
    ...options,
    customClass: {
      title: "!font-semibold !text-[#374151] !pt-[10px]",
      popup: "!font-['Inter'] !rounded-[12px]",
      ...options.customClass,
    },
  });
};

export const confirmationModal = (options, successCb = () => {}, cancelCB = () => {}) => {
  Swal.fire({
    icon: "warning",
    iconColor: "#E02424",
    confirmButtonColor: "#1C64F2",
    title: "Are you sure?",
    text: "You won't be able to revert this",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it",
    cancelButtonText: "No, cancel",
    reverseButtons: true,
    customClass: {
      title: "!font-semibold !text-[#374151] !pt-[10px]",
      popup: "!font-['Inter'] !rounded-[12px]",
      ...options?.customClass,
    },
    ...options,
  }).then((result) => {
    if (result.isConfirmed) {
      
      successCb();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      cancelCB();
    }
  });
};
