import React from 'react'

function Support() {
    return (
        <div className='py-4 w-full min-h-screen relative overflow-hidden'>
            <div className="flex-1 px-8 flex items-center justify-center sm:items-stretch sm:justify-start">
                <p className='text-2xl font-Inter font-[600] mt-[0.1px] text-[#101828] ease-in duration-300'>Support</p>
            </div>

            <hr className='bg-gray-200 h-[1.15px] mt-3' />
        </div>
    )
}

export default Support