const initState = {
  currentCredits: 0,
  total_purchased_credits: 0,
  user: null,
  stripe: null,
  totalLeadlists: 0,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "Set_Current_Credits":
      return {
        ...state,
        currentCredits: action.payload.current_credits,
        total_purchased_credits: action.payload.total_purchased_credits,
        user: action.payload.user,
        stripe: action.payload.stripeDetails,
        totalLeadlists: action.payload.totalLeadlists,
      };

    default:
      return state;
  }
};
