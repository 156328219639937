import { connect } from "react-redux";
import UploadACSV from "./UploadACSV";
import { useNavigate, useLocation } from "react-router-dom";
import CreditsUsed from "./CreditsUsed";
import PreviousRuns from "./PreviousRuns";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { failAlert, successAlert } from "../../components/sweetAlert";
import { getInfoOfAllRun } from "../../redux/actions/enrichmentAction";
import { getSavedSearches } from "../../redux/actions/searchAction";
import { updateStripe } from "../../redux/actions/stripeAction";
import { getUserInfo } from "../../redux/actions/globalAction";
import axios from "axios";
export const Enrichment = (props) => {
  const { getInfoOfAllRun, runs, getSavedSearches, updateStripe, getUserInfo } =
    props;

  const location = useLocation();
  const navigate = useNavigate();
  const [result, setResult] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const dispatch = useDispatch();
  const session = new URLSearchParams(location.search).get("session");
  const checkout = new URLSearchParams(location.search).get("checkout");
  React.useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  const handleOpenCSVReader = (res, fileInfo) => {
    // console.log(res, "res")
    // res.data.pop();
    // console.log(res.data, "res.data");

    let data = res.data;
    let finalData = [];
    for (let i = 0; i < data.length; i++) {
      let curData = data[i];
      let c = 0;
      for (let j = 0; j < curData.length; j++) {
        if (!curData[j]) {
          c++;
        }
      }
      if (c < curData.length) {
        finalData.push(curData);
      }
    }
    // console.log(finalData, "finalData");
    setResult(finalData);
    setFileName(fileInfo.name);
    // dispatch({ type: 'SET_UPLOADED_CSV_DATA', data: result.data })
  };

  const handleDeleteUploaded = () => {
    // dispatch({ type: 'SET_UPLOADED_CSV_DATA', data: [] })
  };

  async function fetchData(pageNumber) {
    try {
      setLoading(true);
      const { status, totalCount } = await getInfoOfAllRun(pageNumber + 1);
      if (status) {
        setTotalRowsCount(totalCount);
      }
    } catch (error) {
      // console.log(error, "Error in Datalake feting list");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(pageNumber);
  }, [pageNumber]);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage.page);
  };

  useEffect(() => {
    dispatch({
      type: "Reset_Enrichment_Details",
    });
    getSavedSearches();
  }, []);

  React.useEffect(() => {
    async function saveData() {
      if (session !== null && session !== undefined) {
        try {
          const res = await updateStripe(session);
          if (res) {
            successAlert({
              title: "Payment Successfull!",
            });
            await getUserInfo();
            navigate("/enrichment");
          }
        } catch (error) {
          // console.log(error);
        }
      }
    }

    if (checkout === "fail") {
      failAlert({
        title: "Payment Failed",
      });
      navigate("/enrichment");
      return;
    } else {
      saveData();
    }
  }, [session]);

  return (
    <>
      <div className="py-4 w-full fade-in min-h-screen relative overflow-hidden">
        <div className="flex-1 px-8 flex items-center justify-center sm:items-stretch sm:justify-start">
          <p className="text-2xl fade-in font-Inter font-[600] mt-[0.1px] text-[#101828] ease-in duration-300">
            Enrichment
          </p>
        </div>
        <hr className="bg-gray-200 h-[1.15px] mt-[13px]" />

        <div className="">
          <div className="flex fade-in item-center gap-x-5 px-8 py-5 w-full">
            <UploadACSV
              handleOpenCSVReader={handleOpenCSVReader}
              handleDeleteUploaded={handleDeleteUploaded}
              result={result}
              setResult={setResult}
              fileName={fileName}
              setFileName={setFileName}
            />

            <CreditsUsed />
          </div>

          <PreviousRuns
            data={runs}
            handlePageChange={handlePageChange}
            loading={loading}
            pageNumber={pageNumber}
            totalRowsCount={totalRowsCount}
          />
        </div>
      </div>

      {/* {
                result && result.length > 0 &&
                <MappingModal result={result} setResult={setResult} />
            } */}
    </>
  );
};

const mapStateToProps = (state) => ({
  runs: state.enrichment.runs,
  user: state.global.user,
});

const mapDispatchToProps = {
  getInfoOfAllRun,
  getSavedSearches,
  updateStripe,
  getUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Enrichment);
