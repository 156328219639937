const initState = {
    searchTableData: [],
    employeeData: [],
    savedSearchLoading: false,
    savedTableResult: [],
    currentSearchId: null,
    queryData: null,
    totalSavedResult: 0

};

export default (state = initState, action) => {
    switch (action.type) {
        case "SET_SEARCH_TABLE_DATA":
            return {
                ...state,
                searchTableData: action.payload
            };
        case "SET_QUERY_DATA":
            return {
                ...state,
                queryData: action.payload
            };
        case 'SET_CURRENT_SEARCH_ID':
            return {
                ...state,
                currentSearchId: action.payload
            }
        case 'SET_TOTAL_SAVED_RESULT':
            return {
                ...state,
                totalSavedResult: action.payload
            }
        case "SET_EMPLOYEE_DATA":
            return {
                ...state,
                employeeData: action.payload
            };
        case 'SET_SAVED_TABLE_RESULT':
            return {
                ...state,
                savedTableResult: action.payload
            };
        case 'SET_SAVED_SEARCH_LOADING':
            return {
                ...state,
                savedSearchLoading: action.payload
            };
        case 'SET_RECORD_ID':
            //push new result into the recordID object

            var savedTableResult = state.savedTableResult
            var recordID = action.payload.recordID
            var data = action.payload.data
            var index = savedTableResult.findIndex((item) => item._id === recordID)

            if (index !== -1) {
                let result = savedTableResult[index].data
                savedTableResult[index].data = [...result, ...data]
            }
            return {
                ...state,
                savedTableResult: savedTableResult
            };

        default:
            return state;
    }
};
