import { combineReducers } from "redux";

import auth from "./auth";
import enrichment from "./enrichment";
import global from "./global";
import datalake from "./datalake";
import stripe from "./stripe";
import search from "./search";

export default combineReducers({
  auth: auth,
  enrichment: enrichment,
  global: global,
  dataLake: datalake,
  stripe: stripe,
  search: search,
});
