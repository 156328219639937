import instance from "./AxiosInstance/authenticated";
import axios from "axios";
import { toast } from "sonner";

export const getInfoOfAllRun = (pageNo) => async (dispatch) => {
  try {
    const { data, status } = await instance.get(
      `/scrapper/get-tasks/${pageNo}`
    );
    if (status === 200) {
      dispatch({
        type: "Set_Runs",
        payload: data.results,
      });
      return {
        status: true,
        totalCount: data.total_tasks_count ? Number(data.total_tasks_count) : 0,
      };
    }
    return {
      status: false,
      totalCount: 0,
    };
  } catch (error) {
    console.log(error, "Error in signin");
    return {
      status: false,
      totalCount: 0,
    };
  }
};

export const startANewEnrichment =
  (
    enrichmentBlob,
    enrichmentName,
    noOfRows,
    appoloKeyLabel,
    isWaterFall,
    tags,
    contactsPerCompany,
    addEmails,
    excludeTitles
  ) =>
  async (dispatch) => {
    try {
      // console.log(enrichmentBlob, "enrichmentBlob");
      var data = new FormData();
      data.append("file", enrichmentBlob);
      data.append("name", enrichmentName);
      data.append("rows", noOfRows);
      data.append("appolo_key_label", appoloKeyLabel);
      data.append("is_waterfall", isWaterFall);
      data.append("titles", tags);
      data.append("contacts_per_company", contactsPerCompany);
      data.append("add_emails", addEmails);
      data.append("exclude_titles", excludeTitles);
      // data.append("task_name", inputData.leadistName.toString());
      // data.append("detect_shop", blogEnrichment.detect_shop.toString());
      // // data.append("detect_merch", blogEnrichment.detect_merch.toString());
      // data.append("detect_keywords", blogEnrichment.detect_keywords.toString());
      // data.append("seo_metrics", otherEnrichment.seo_metrics.toString());
      // data.append("domain_metrics", otherEnrichment.domain_metrics.toString());
      // data.append("detect_media", blogEnrichment.detect_media.toString());
      // data.append("detect_blogs", blogEnrichment.detect_blogs.toString());
      // data.append(
      //   "detect_newsletter",
      //   blogEnrichment.detect_newsletter.toString()
      // );
      // data.append("detect_casestudy", blogEnrichment.detect_casestudy.toString());
      // data.append("keywords", keywords.toString());
      // data.append("verify_email", otherEnrichment.verify_email.toString());
      // data.append("fetch_mobile", otherEnrichment.fetch_mobile.toString());
      // data.append("fetch_email", otherEnrichment.fetch_email.toString());
      // data.append("fb_enrich", socialEnrichment.fb_enrich.toString());
      // data.append("insta_enrich", socialEnrichment.insta_enrich.toString());
      // data.append("li_enrich", socialEnrichment.li_enrich.toString());
      // data.append(
      //   "li_personal_enrich",
      //   socialEnrichment.li_personal_enrich.toString()
      // );
      // data.append("tiktok_enrich", socialEnrichment.tiktok_enrich.toString());
      // console.log(inputData, "data");
      // data.append("rowsToEnrich", inputData.numberOfRows.toString());

      const accessToken = localStorage.getItem("accessToken");
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/scrapper/scrap`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      };

      try {
        const response = await axios(config);
        // console.log(response, "response");
        if (response.status === 200) {
          return {
            status: true,
            message: response.data.task_id,
            code: 200,
          };
        } else {
          return {
            status: false,
            message: "Something went wrong!",
            code: 400,
          };
        }
      } catch (error) {
        console.log(error);
        return {
          status: false,
          message: "Something went wrong!",
          code: error.response.status,
        };
      }
    } catch (error) {
      console.log(error, "Error in signin");
      return {
        status: false,
        message: "Something went wrong!",
      };
    }
  };

export const getEnrichmentById = (enrichmentId) => async (dispatch) => {
  try {
    const { data, status } = await instance.get(
      `/scrapper/get-task-info/${enrichmentId}`
    );
    if (status === 200) {
      dispatch({
        type: "Set_Enrichment_Details",
        payload: data,
      });
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signin");
    return false;
  }
};

function sortModelToUrlParam(sortModel) {
  if (sortModel.length === 0) {
    // return "first_name=asc";
    return "serial_number=asc";
  }

  return sortModel.map((item) => `${item.field}=${item.sort}`).join(",");
}

export const getEnrichmentResultById =
  (enrichmentId, paginationModal, sortModel, filterModel) =>
  async (dispatch) => {
    try {
      // console.log("enrichmentId", enrichmentId);
      const { data, status } = await instance.get(`/scrapper/get-task-result`, {
        params: {
          task_id: enrichmentId,
          paginationModal: paginationModal,
          sortModel: sortModel,
          filterModel: filterModel,
        },
      });
      if (status === 200) {
        dispatch({
          type: "Set_Enrichment_Results",
          payload: data,
        });
        return true;
      }
    } catch (error) {
      console.log(error, "Error in signin");
      return false;
    }
  };

export const cancelEnrichment = (enrichmentId) => async (dispatch) => {
  try {
    const { data, status } = await instance.get(
      `/scrapper/cancel-task/${enrichmentId}`
    );
    if (status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error, "Error in signin");
    return false;
  }
};

export const addToDataLake = (list_id, task_id) => async (dispatch) => {
  try {
    const { data, status } = await instance.post(`/datalake/add-to-list`, {
      list_id,
      task_id,
    });
    if (status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error, "Error in signin");
    return false;
  }
};

export const getTaskLogs = (task_id) => async (dispatch) => {
  try {
    const { data, status } = await instance.post(`/scrapper/get-task-logs`, {
      task_id,
    });
    if (status === 200) {
      return {
        status: true,
        data: data,
      };
    }
    return {
      status: false,
      data: [],
    };
  } catch (error) {
    console.log(error, "Error in signin");
    return {
      status: true,
      data: [],
    };
  }
};

export const renameEnrichment = (enrichmentId, name) => async (dispatch) => {
  try {
    // console.log(enrichmentId, name);
    const { data, status } = await instance.post("/scrapper/rename", {
      enrichmentId: enrichmentId,
      name: name,
    });

    if (status === 200) {
      toast.success("Enrichment named changed successfully!");
      dispatch({
        type: "UPDATE_ENRICHMENT_NAME",
        payload: {
          name: name,
          enrichmentId: enrichmentId,
        },
      });
    }
  } catch (error) {
    console.error(
      "[Error] something went wrong when updating the enrichment name",
      error
    );
  }
};
