import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { Tree } from "antd";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { startANewEnrichment } from "../../redux/actions/enrichmentAction";
import { connect } from "react-redux";
import csvtojson from "csvtojson";
import Swal from "sweetalert2";
import axios from "axios";
import { json2csv } from "json-2-csv";
import ConfirmationModal from "../ConfirmationModal";

var data = [
  {
    key: 1,
    title: "Add and verify emails and direct phone numbers",
    description:
      "Email validation : 1 credit / row | Fetch mobile number, Add email : 3 credit / row",
    options: [
      {
        key: `verify_email`,
        title: "Verify email addresses",
        children: [],
      },
      {
        key: `fetch_email`,
        title: "Add Email Address",
        children: [],
      },
      {
        key: `fetch_mobile`,
        title: "Fetch mobile numbers (Company phones will be included)",
        children: [],
      },
    ],
  },
  {
    key: 2,
    title: "Domain performance",
    description: "Domain performance | 1 credit/row",
    options: [
      {
        key: `seo_metrics`,
        title: "SEO data (domain authority, backlinks, spam score etc)",
        children: [],
      },
      {
        key: `domain_metrics`,
        title:
          "Advertising data (PPC, monthly ad spend, clicks, traffic, keywords etc)",
        children: [],
      },
    ],
  },
  {
    key: 3,
    title: "Social Enrichment",
    description: "Social Enrichment | 4 credits/row",
    options: [
      {
        key: `insta_enrich`,
        checked: true,
        title:
          "Instagram Enrichment (find IG link, scrape posts, likes & more)",
        children: [],
      },
      {
        key: `li_enrich`,
        title:
          "Linkedin Enrichment (find links, scrape posts, comments & more)",
        children: [],
      },
      {
        key: `li_personal_enrich`,
        title:
          "Personal Linkedin Enrichment (Personal linkedin url must be mapped to run this)",
        children: [],
      },
      {
        key: `fb_enrich`,
        title: "Facebook Enrichment (find FB link, scrape posts, likes & more)",
        children: [],
      },
      {
        key: `tiktok_enrich`,
        title:
          "Tiktok Enrichment (find Tiktok link, scrape posts, likes & more)",
        children: [],
      },
    ],
  },
  {
    key: 4,
    title: "Website content",
    description: "Website content | 1 credit/row",
    options: [
      // {
      //   key: `detect_shop`,
      //   title: "Detect Shop (does domain have an online store)",
      //   children: [],
      // },
      {
        key: `detect_keywords`,
        title: "Detect Keywords (find keywords in website content)",
        inputVisible: false,
        children: [],
      },
      // {
      //   key: `detect_media`,
      //   title: "Detect Media (find media links in website content)",
      //   children: [],
      // },
      {
        key: `detect_blogs`,
        title: "Detect Blogs (does domain have a blog)",
        children: [],
      },
      // {
      //   key: `detect_newsletter`,
      //   title: "Newsletter/email form (do they have a newsletter)",
      //   children: [],
      // },
      // {
      //   key: `detect_casestudy`,
      //   title: "Detect Case Study (do they have case studies, portfolio etc)",
      //   children: [],
      // },
    ],
  },
];

export const BuyCredit = (props) => {
  const { startANewEnrichment, result, fileName, handleCancel, csvMapIndex } =
    props;
  const [checkedKeys, setCheckedKeys] = useState([[], [], []]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [maxRowCount, setMaxRowCount] = useState(0);
  const [inputData, setInputdata] = useState({
    leadistName: "",
    numberOfRows: null,
  });
  const [keywords, setKeywords] = useState("");

  const [emailEnrichment, setEmailEnrichment] = useState({
    verify_email: false,
    fetch_mobile: false,
    fetch_email: false,
  });

  const [socialEnrichment, setSocialEnrichment] = useState({
    insta_enrich: false,
    li_enrich: false,
    li_personal_enrich: false,
    fb_enrich: false,
    tiktok_enrich: false,
  });

  const [blogEnrichment, setBlogEnrichment] = useState({
    detect_blogs: false,
    detect_casestudy: false,
    detect_keywords: false,
    detect_media: false,
    // detect_merch: false,
    detect_newsletter: false,
    detect_shop: false,
  });

  const [otherEnrichment, setOtherEnrichment] = useState({
    seo_metrics: false,
    domain_metrics: false,
  });

  const [EmailVerifyCount, setEmailVerifyCount] = useState(0);
  const [socialCreditCount, setSocialCreditCount] = useState(0);
  const [blogCreditCount, setBlogCreditCount] = useState(0);
  const [otherCreditCount, setOtherCreditCount] = useState(0);

  const [showEnrichConfirmModal, setShowEnrichConfirmModal] = useState(false);

  const calculateCredit = (data) => {
    var count = 0;
    // console.log('calculate input', inputData.numberOfRows)
    //CREDIT_SOCIAL this should depend on the type of enrichment and should be used from env

    //email verification
    var total = 0;

    for (const key in data) {
      count += data[key];

      if (key == "verify_email" && data[key]) {
        total += 1;
      }
      if (key == "fetch_mobile" && data[key]) {
        total += 3;
      }
      if (key == "fetch_email" && data[key]) {
        total += 3;
      }

      if (
        [
          "insta_enrich",
          "li_enrich",
          "li_personal_enrich",
          "fb_enrich",
          "tiktok_enrich",
        ].includes(key) &&
        data[key]
      ) {
        total += 4;
      }

      if (
        [
          "detect_shop",
          "detect_keywords",
          "detect_media",
          "detect_blogs",
          "detect_newsletter",
          "detect_casestudy",
        ].includes(key) &&
        data[key]
      ) {
        total += 1;
      }

      if (["seo_metrics", "domain_metrics"].includes(key) && data[key]) {
        total += 1;
      }
    }

    return total;
  };

  const calcTotalCreds = (feasibleRows = null) => {
    let total =
      EmailVerifyCount + socialCreditCount + blogCreditCount + otherCreditCount;

    let rows = inputData.numberOfRows;
    if (feasibleRows) {
      rows = feasibleRows;
    }
    // console.log(rows);
    total = total * rows;
    if (!rows) {
      rows = 0;
    }
    // console.log(rows, result);
    if (emailEnrichment.fetch_email) {
      if (csvMapIndex["email"]) {
        total = total - 3 * rows;
        for (let i = 1; i <= rows; i++) {
          if (
            result[i][csvMapIndex["email"]] === "" ||
            !result[i][csvMapIndex["email"]]
          ) {
            total = total + 3;
          }
        }
      }
    }

    return total;
  };

  useEffect(() => {
    // console.log(result, "result");
    if (result && result.length > 0 && fileName) {
      var count = result.length - 1;
      // console.log(result[result.length - 1], "result");
      for (let i = result.length - 1; i >= 0; i--) {
        if (result[i].length <= 1) count--;
        else break;
      }

      // console.log("count", count, result.length);
      setInputdata({
        ...inputData,
        numberOfRows: count,
        leadistName: fileName,
      });
      setMaxRowCount(count);
    }
  }, [result, fileName]);

  useEffect(() => {
    var temp = Object.assign({}, socialEnrichment);
    var count = calculateCredit(temp);
    // console.log("calculate input", count);
    setSocialCreditCount(count);
  }, [socialEnrichment, inputData]);

  useEffect(() => {
    var temp = Object.assign({}, blogEnrichment);
    var count = calculateCredit(temp);
    setBlogCreditCount(count);
  }, [blogEnrichment, inputData]);

  useEffect(() => {
    var temp = { ...otherEnrichment };
    var count = calculateCredit(temp);
    setOtherCreditCount(count);
  }, [otherEnrichment, inputData]);

  useEffect(() => {
    var temp = { ...emailEnrichment };
    var count = calculateCredit(temp);
    setEmailVerifyCount(count);
  }, [emailEnrichment, inputData]);

  var mappingArray = [
    {
      label: "Website",
      value: "website",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "Company",
      value: "company",
    },
    {
      label: "First Name",
      value: "first_name",
    },
    {
      label: "Last Name",
      value: "last_name",
    },
    {
      label: "Title",
      value: "title",
    },
    {
      label: "Seniority",
      value: "seniority",
    },
    {
      label: "Department",
      value: "department",
    },
    {
      label: "Phone",
      value: "phone",
    },
    {
      label: "Number of employees",
      value: "number_of_employees",
    },
    {
      label: "Industry",
      value: "industry",
    },
    {
      label: "Facebook url",
      value: "facebook_url",
    },
    {
      label: "Instagram url",
      value: "instagram_url",
    },
    {
      label: "Tiktok url",
      value: "tiktok_url",
    },
    {
      label: "Linkedin url",
      value: "linkedin_url",
    },
    {
      label: "Personal Linkedin url",
      value: "personal_linkedin_url",
    },
    {
      label: "Twitter url",
      value: "twitter_url",
    },

    {
      label: "City",
      value: "city",
    },
    {
      label: "State",
      value: "state",
    },
    {
      label: "Country",
      value: "country",
    },
    {
      label: "Description",
      value: "description",
    },
  ];

  const createCSV = async (data, csvMapIndex, feasibleRows = null) => {
    const csvContent = [];

    // // Add the data rows
    let avRows = null;
    if (feasibleRows) {
      avRows = feasibleRows;
    } else {
      avRows = inputData.numberOfRows;
    }
    // console.log(data[data.length - 1]);

    for (let i = 1; i < data.length; i++) {
      const row = {};

      if (data[i].length > 1) {
        row["serial_number"] = i;
        mappingArray.map((item) => {
          if (
            csvMapIndex[item.value] !== null &&
            csvMapIndex[item.value] < data[i].length
          ) {
            if (item.value == "company") {
              row["Company"] = data[i][csvMapIndex[item.value]];
            } else if (item.value == "website") {
              row["Website"] = data[i][csvMapIndex[item.value]];
            } else {
              row[item.value] = data[i][csvMapIndex[item.value]];
            }
          } else {
            row[item.value] = " ";
          }
        });

        csvContent.push(row);
      }
    }

    // console.log(csvContent, "csvContent");

    const csv = await json2csv(csvContent);

    // console.log(csv, "csvContent");

    // console.log(csv, "csvContent");

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    return blob;
  };

  const handleOnChange = (e, key) => {
    // console.log(key, e);
    switch (key) {
      case 3:
        var temp = {
          insta_enrich: false,
          li_enrich: false,
          li_personal_enrich: false,
          fb_enrich: false,
          tiktok_enrich: false,
        };
        e.forEach((item) => {
          if (temp.hasOwnProperty(item)) {
            temp[item] = true;
          }
        });
        setSocialEnrichment(temp);
        break;
      case 4:
        var temp = {
          detect_blogs: false,
          detect_casestudy: false,
          detect_keywords: false,
          detect_media: false,
          // detect_merch: false,
          detect_newsletter: false,
          detect_shop: false,
        };
        e.forEach((key) => {
          if (temp.hasOwnProperty(key)) {
            temp[key] = true;
          }
        });
        setBlogEnrichment(temp);
        break;
      case 2:
        var temp = {
          seo_metrics: false,
          domain_metrics: false,
        };
        e.forEach((key) => {
          if (temp.hasOwnProperty(key)) {
            temp[key] = true;
          }
        });
        setOtherEnrichment(temp);
        break;
      case 1:
        var temp = {
          verify_email: false,
          fetch_mobile: false,
          fetch_email: false,
        };
        e.forEach((key) => {
          if (temp.hasOwnProperty(key)) {
            temp[key] = true;
          }
        });
        setEmailEnrichment(temp);
        break;
    }
  };

  const handleRunEnrichment = async (feasibleRows = null) => {
    // console.log("in starting enrichment");
    // console.log("input data ", inputData);
    // console.log("social enrichment ", socialEnrichment);
    // console.log("blog enrichment ", blogEnrichment);
    // console.log("other enrichment ", otherEnrichment);
    // console.log("email enrichment ", emailEnrichment);
    // console.log("result", result);
    // console.log("csvMapIndex", csvMapIndex);
    // return;
    // console.log(csvMapIndex);
    // return;
    try {
      if (
        socialEnrichment.li_personal_enrich == true &&
        csvMapIndex["personal_linkedin_url"] == null
      ) {
        Swal.fire({
          text: "Please map personal Linkedin url to enrich",
          icon: "error",
          allowOutsideClick: true,
        });
        return;
      }
      if (emailEnrichment.fetch_email == true) {
        let conditionMet = false;
        if (csvMapIndex["linkedin_url"] != null) {
          conditionMet = true;
        }
        if (
          csvMapIndex["first_name"] != null &&
          csvMapIndex["last_name"] != null &&
          csvMapIndex["website"] != null
        ) {
          conditionMet = true;
        }
        if (!conditionMet) {
          Swal.fire({
            text: "Please map either linkedin url or first name, last name and website to enrich email address",
            icon: "error",
            allowOutsideClick: true,
          });
          handleCancel();
          return;
        }
      }
      if (emailEnrichment.verify_email == true) {
        if (csvMapIndex["email"] == null) {
          Swal.fire({
            text: "Please map email address to verify",
            icon: "error",
            allowOutsideClick: true,
          });
          handleCancel();
          return;
        }
      }
      if (emailEnrichment.fetch_mobile == true) {
        let conditionMet = false;
        if (csvMapIndex["personal_linkedin_url"] != null) {
          conditionMet = true;
        }
        if (csvMapIndex["email"] != null) {
          conditionMet = true;
        }
        if (!conditionMet) {
          Swal.fire({
            text: "Please map either personal linkedin url or email to enrich mobile number",
            icon: "error",
            allowOutsideClick: true,
          });
          handleCancel();
          return;
        }
      }
      // console.log("hi", feasibleRows);
      let totalCredits = calcTotalCreds(feasibleRows);
      // console.log(totalCredits);
      // console.log(totalCredits, props.user.current_credits);
      if (totalCredits > props.user.current_credits) {
        // Swal.fire({
        //     text:
        //         "You don't have enough credits to perform this enrichment, this enrichment costs " +
        //         totalCredits +
        //         " credits and you have " +
        //         props.user.current_credits +
        //         " credits",
        //     icon: "error",
        //     allowOutsideClick: true,
        // });
        setShowEnrichConfirmModal(true);
        return;
      }

      setLoading(true);
      //create new blob of number of rows length
      const enrichmentBlob = await createCSV(result, csvMapIndex, feasibleRows);

      let status = false;
      Object.values(blogEnrichment).map((value) => {
        if (value) {
          status = true;
        }
      });

      Object.values(otherEnrichment).map((value) => {
        if (value) {
          status = true;
        }
      });

      Object.values(socialEnrichment).map((value) => {
        if (value) {
          status = true;
        }
      });

      Object.values(emailEnrichment).map((value) => {
        if (value) {
          status = true;
        }
      });
      // console.log(inputData, feasibleRows);
      // return;
      var temp = { ...otherEnrichment, ...emailEnrichment };
      if (status) {
        const { status, message, code } = await startANewEnrichment(
          {
            ...inputData,
            numberOfRows: feasibleRows ? feasibleRows : inputData.numberOfRows,
          },
          enrichmentBlob,
          blogEnrichment,
          keywords,
          temp,
          socialEnrichment
        );
        if (code == 503) {
          Swal.fire({
            text: "You have reached maximum amount of concurrent scraps",
            icon: "error",
            allowOutsideClick: true,
          });
        } else {
          if (status) {
            navigate(`enrichment-details/${message}`);
          } else {
            handleCancel();
          }
        }
      } else {
        Swal.fire({
          text: "Please select an enrichment to perform!",
          icon: "error",
          allowOutsideClick: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      // handleCancel();
      setLoading(false);
    }
  };

  const handleSelectAll = (index, isChecked) => {
    const keys = data[index].options.map((option) => option.key);
    switch (index) {
      case 2:
        const tempSocial = { ...socialEnrichment };
        for (const key of keys) {
          tempSocial[key] = isChecked;
        }
        setSocialEnrichment(tempSocial);
        break;
      case 3:
        const tempBlog = { ...blogEnrichment };
        for (const key of keys) {
          tempBlog[key] = isChecked;
        }
        setBlogEnrichment(tempBlog);
        break;
      case 1:
        const tempOther = { ...otherEnrichment };
        for (const key of keys) {
          tempOther[key] = isChecked;
        }
        setOtherEnrichment(tempOther);
        break;
      case 0:
        const tempEmail = { ...emailEnrichment };
        for (const key of keys) {
          tempEmail[key] = isChecked;
        }
        setEmailEnrichment(tempEmail);
        break;
    }
  };

  const calcFeasibleRows = () => {
    let totalUsed = 0;
    let feasibleRows = 0;
    for (let i = 1; i < result.length; i++) {
      let cur =
        EmailVerifyCount +
        socialCreditCount +
        blogCreditCount +
        otherCreditCount;
      if (emailEnrichment.fetch_email && csvMapIndex["email"]) {
        if (
          result[i][csvMapIndex["email"]] &&
          result[i][csvMapIndex["email"]].toString() != ""
        ) {
          cur = cur - 3;
        }
      }
      if (totalUsed + cur <= props.user.current_credits) {
        totalUsed = totalUsed + cur;
        feasibleRows = feasibleRows + 1;
      } else break;
    }
    return [totalUsed, feasibleRows];
  };

  return (
    <>
      <Modal width={"w-3/5 h-[86%]"}>
        <div className="px-1 overflow-auto relative h-[100%]">
          <div className="sticky top-0 z-10 bg-white">
            <div className="flex  item-center gap-x-5">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="4"
                  y="4"
                  width="48"
                  height="48"
                  rx="24"
                  fill="#D1FADF"
                />
                <path
                  d="M20.5 38V33M20.5 23V18M18 20.5H23M18 35.5H23M29 19L27.2658 23.5089C26.9838 24.2421 26.8428 24.6087 26.6235 24.9171C26.4292 25.1904 26.1904 25.4292 25.9171 25.6235C25.6087 25.8428 25.2421 25.9838 24.5089 26.2658L20 28L24.5089 29.7342C25.2421 30.0162 25.6087 30.1572 25.9171 30.3765C26.1904 30.5708 26.4292 30.8096 26.6235 31.0829C26.8428 31.3913 26.9838 31.7579 27.2658 32.4911L29 37L30.7342 32.4911C31.0162 31.7579 31.1572 31.3913 31.3765 31.0829C31.5708 30.8096 31.8096 30.5708 32.0829 30.3765C32.3913 30.1572 32.7579 30.0162 33.4911 29.7342L38 28L33.4911 26.2658C32.7579 25.9838 32.3913 25.8428 32.0829 25.6235C31.8096 25.4292 31.5708 25.1904 31.3765 24.9171C31.1572 24.6087 31.0162 24.2421 30.7342 23.5089L29 19Z"
                  stroke="#039855"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="4"
                  y="4"
                  width="48"
                  height="48"
                  rx="24"
                  stroke="#ECFDF3"
                  stroke-width="8"
                />
              </svg>
              <div>
                <div className="">
                  <p className="text-lg text-secondary font-semibold text-Inter">
                    Create an Enrichment
                  </p>
                  <p className="text-sm text-secondarySupport text-Inter">
                    Some basic information about your enrichment.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-x-5 mt-4">
              <div className="col-span-8">
                <label
                  htmlFor="email"
                  className="text-[#4E5674] font-[500] font-Inter"
                >
                  Lead List Name
                </label>
                <div className="mb-4 mt-1 flex items-center rounded-lg border-2 py-2 px-3">
                  <input
                    onChange={(e) => {
                      setInputdata({
                        ...inputData,
                        leadistName: e.target.value,
                      });
                    }}
                    value={inputData.leadistName}
                    className="border-none outline-none w-full font-Inter"
                    type="text"
                    name="LeadName"
                    id="LeadName"
                    placeholder="e.g. Website design agencies 12th June"
                  />
                </div>
              </div>
              <div className="col-span-4">
                <label
                  htmlFor="email"
                  className="text-[#4E5674] font-[500] font-Inter"
                >
                  Number of rows
                </label>
                <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
                  <input
                    onChange={(e) => {
                      if (maxRowCount >= Number(e.target.value)) {
                        setInputdata({
                          ...inputData,
                          numberOfRows: Number(e.target.value),
                        });
                      }
                    }}
                    value={Number(inputData.numberOfRows)}
                    className="border-none outline-none w-full font-Inter"
                    type="number"
                    max={maxRowCount}
                    name="NumberofRows"
                    id="NumberofRows"
                    placeholder="10000"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="overflow-y-auto h-fit scrollbar-none space-y-4 pb-5">
              {data.map((item, index) => (
                <div
                  key={index}
                  className=" px-4 py-3 border-[1px] border-gray-300 rounded-lg"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <p className="text-md text-secondary font-semibold text-Inter">
                        {item.title}
                      </p>
                      <p className="text-sm text-secondarySupport text-Inter">
                        {item.description}
                      </p>
                    </div>
                    <div>
                      {/* Add select all filed */}
                      <div>
                        <label className="ml-2 text-sm text-secondarySupport text-Inter">
                          <input
                            type="checkbox"
                            id="selectAll"
                            className="form-checkbox mr-1.5 h-3 w-3 rounded-lg hover:bg-primary accent-primary text-primary"
                            onChange={(e) => {
                              handleSelectAll(index, e.target.checked);
                            }}
                          />
                          Select All
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Tree
                      treeData={item.options}
                      onCheck={(checkedKeys, e) => {
                        handleOnChange(checkedKeys, item.key);
                      }}
                      checkedKeys={
                        index === 0
                          ? Object.keys(emailEnrichment).filter(
                              (key) => emailEnrichment[key]
                            )
                          : index === 1
                          ? Object.keys(otherEnrichment).filter(
                              (key) => otherEnrichment[key]
                            )
                          : index === 2
                          ? Object.keys(socialEnrichment).filter(
                              (key) => socialEnrichment[key]
                            )
                          : Object.keys(blogEnrichment).filter(
                              (key) => blogEnrichment[key]
                            )
                      }
                      checkable
                    />
                    {index === 3 && blogEnrichment.detect_keywords && (
                      <div className="mt-1 px-6">
                        <label
                          htmlFor="keywords"
                          className="text-slate-600 text-sm  font-Inter"
                        >
                          Keywords
                        </label>
                        <div className="mb-1 mt-1 flex items-center rounded-lg border-2 py-1.5 px-3">
                          <input
                            className="border-none outline-none text-sm w-full font-Inter"
                            type="text"
                            name="keywords"
                            id="keywords"
                            autoComplete="off"
                            onChange={(e) => {
                              setKeywords(e.target.value);
                            }}
                            placeholder="enter the keywords sperated by comma eg. web design, web development"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <p className="text-Inter font-[500] text-md text-right text-green-500">
                    +
                    {index === 0
                      ? EmailVerifyCount
                      : index === 1
                      ? otherCreditCount
                      : index === 2
                      ? socialCreditCount
                      : blogCreditCount}
                    {` Credits`}
                  </p>
                </div>
              ))}
            </div>

            <div className="mt-3 w-full px-1 space-y-2 sticky bottom-0 bg-white">
              <div className="w-fit px-2 ml-auto">
                <p className="text-Inter text-sm text-[#8D96C3] leading-3">
                  Total Credits
                </p>
                <p className="text-Inter text-2xl text-[#4E5674] font-[700] text-center leading-8">
                  {calcTotalCreds()}
                </p>
              </div>
              <div className="flex item-center justify-between ">
                <button
                  onClick={() => {
                    handleCancel();
                  }}
                  className="px-4 py-2 text-sm text-Inter font-[500] rounded-lg border-[1px] border-gray-400"
                >
                  Back
                </button>
                {loading ? (
                  <button
                    disabled
                    type="button"
                    className="px-4 py-2 text-sm text-Inter bg-primary text-white font-[500] rounded-lg border-[1px] border-primary"
                  >
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleRunEnrichment();
                    }}
                    className="px-4 py-2 text-sm text-Inter bg-primary text-white font-[500] rounded-lg border-[1px] border-primary"
                  >
                    Start Enrichment
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {showEnrichConfirmModal && (
        <Modal width={"w-1/4 z-[999]"}>
          <div className="text-center w-full">
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              className="text-center mx-auto"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
              <path
                d="M23 19V22.4C23 22.9601 23 23.2401 23.109 23.454C23.2049 23.6422 23.3578 23.7951 23.546 23.891C23.7599 24 24.0399 24 24.6 24H31.4C31.9601 24 32.2401 24 32.454 23.891C32.6422 23.7951 32.7951 23.6422 32.891 23.454C33 23.2401 33 22.9601 33 22.4V20M33 37V30.6C33 30.0399 33 29.7599 32.891 29.546C32.7951 29.3578 32.6422 29.2049 32.454 29.109C32.2401 29 31.9601 29 31.4 29H24.6C24.0399 29 23.7599 29 23.546 29.109C23.3578 29.2049 23.2049 29.3578 23.109 29.546C23 29.7599 23 30.0399 23 30.6V37M37 25.3255V32.2C37 33.8802 37 34.7202 36.673 35.362C36.3854 35.9265 35.9265 36.3854 35.362 36.673C34.7202 37 33.8802 37 32.2 37H23.8C22.1198 37 21.2798 37 20.638 36.673C20.0735 36.3854 19.6146 35.9265 19.327 35.362C19 34.7202 19 33.8802 19 32.2V23.8C19 22.1198 19 21.2798 19.327 20.638C19.6146 20.0735 20.0735 19.6146 20.638 19.327C21.2798 19 22.1198 19 23.8 19H30.6745C31.1637 19 31.4083 19 31.6385 19.0553C31.8425 19.1043 32.0376 19.1851 32.2166 19.2947C32.4184 19.4184 32.5914 19.5914 32.9373 19.9373L36.0627 23.0627C36.4086 23.4086 36.5816 23.5816 36.7053 23.7834C36.8149 23.9624 36.8957 24.1575 36.9447 24.3615C37 24.5917 37 24.8363 37 25.3255Z"
                stroke="#DC6803"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="4"
                y="4"
                width="48"
                height="48"
                rx="24"
                stroke="#FFFAEB"
                stroke-width="8"
              />
            </svg>
            <p className="text-lg text-secondary font-semibold text-Inter mt-3">
              Enough Credits not available for the enrichment
            </p>
            <p className="text-sm text-secondarySupport text-Inter">
              you can execute only {calcFeasibleRows()[1]} rows with{" "}
              {calcFeasibleRows()[0]} credits
              <br></br>
              Total credits available : {props.user.current_credits}
            </p>

            <div className="flex item-center justify-evenly gap-x-5 mt-6">
              <button
                onClick={() => {
                  setShowEnrichConfirmModal(false);
                }}
                className="px-4 py-2 w-full text-sm text-Inter font-[500] rounded-lg border-[1px] border-gray-400"
              >
                Change Rows
              </button>
              {calcFeasibleRows()[1] > 0 && (
                <button
                  onClick={() => {
                    setShowEnrichConfirmModal(false);
                    setInputdata({
                      ...inputData,
                      numberOfRows: calcFeasibleRows()[1],
                    });
                    handleRunEnrichment(calcFeasibleRows()[1]);
                  }}
                  className="px-4 py-2 w-full text-sm text-Inter bg-primary text-white font-[500] rounded-lg border-[1px] border-primary"
                >
                  Run ( {calcFeasibleRows()[1]} rows )
                </button>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.global.user,
});

const mapDispatchToProps = { startANewEnrichment };

export default connect(mapStateToProps, mapDispatchToProps)(BuyCredit);
