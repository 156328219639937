import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

function VerifyEmail() {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const [invalidToken, setInvalidToken] = useState(false);
  const [email, setEmail] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const handleResendEmail = async () => {
    try {
      var config = {
        method: "post",
        url: `${baseUrl}/user/send-email`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { email: email, for: "resend-link" },
      };
      setLoading(true);
      const response = await axios(config);

      if (response.status === 200) {
        setLoading(false);
        Swal.fire({
          text: "Email sent , please check your inbox",
          icon: "success",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status == 404) {
        Swal.fire({
          text: "No account found with this email",
          icon: "error",
          allowOutsideClick: false,
        });
      } else if (error.response.status && error.response.status == 409) {
        Swal.fire({
          text: "Profile already verified",
          icon: "success",
          allowOutsideClick: false,
        });
      } else {
        Swal.fire({
          text: "Something went wrong ",
          icon: "error",
          allowOutsideClick: false,
        });
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        var config = {
          method: "post",
          url: `${baseUrl}/user/verify`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios(config);

        if (response.status === 200) {
          Swal.fire({
            title: "Profile Verified",
            text: "Profile verified successfully",
            icon: "success",
            allowOutsideClick: false,
          });

          setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_REDIRECT_FRONTEND_URL}/signin`;
          }, 3500);
        }
      } catch (error) {
        setInvalidToken(true);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <>
      {(invalidToken === true || !token) && (
        <div className="flex justify-center items-center h-screen">
          <div className="flex flex-col  w-1/2 bg-white shadow-lg rounded-lg p-8 justify-center items-center">
            <>
              <h1 className="text-3xl font-bold mb-8">Invalid/Expired Link</h1>

              <div className="flex">
                <input
                  type="text"
                  className="rounded-l-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Resend email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded-r-lg ml-2"
                  onClick={handleResendEmail}
                >
                  {loading == false ? "Send" : "Sending..."}
                </button>
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
}

export default VerifyEmail;
