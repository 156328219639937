import React from "react";
import { connect } from "react-redux";

import { IoIosArrowDown } from "react-icons/io";
export const MapperDropdown = (props) => {
  const { label, value, setCsvMapIndex, csvMapIndex, result, key } = props;
  const compalsory = ["first_name", "email"];
  return (
    <div className={`flex justify-start items-center py-3  `}>
      <p className="font-Inter w-5/12 tracking-wide text-[#101828] font-semibold text-sm ">
        {label}
        {compalsory.includes(value) && <span className="text-red-600"> *</span>}
      </p>

      <div className="relative  w-7/12">
        <button
          type="button"
          onClick={() => {
            document
              .getElementById(`map_${value}_dropdown`)
              .classList.toggle("hidden");
          }}
          className="flex w-full items-center justify-between rounded-lg bg-white p-2 ring-1 ring-gray-300"
        >
          <span className="text-Inter text-sm font-[400] text-slate-600 px-1">
            {result[0][csvMapIndex[value]]
              ? result[0][csvMapIndex[value]]
              : `Please select a field`}
          </span>
          <span className="text-2xl w-5 h-5 grid place-content-center">
            <IoIosArrowDown className="w-5 mr-2" />
          </span>
        </button>

        <ul
          id={`map_${value}_dropdown`}
          className="z-20 hidden absolute mt-2 w-full h-fit rounded bg-white ring-1 ring-gray-300 max-h-40 overflow-y-auto scroll-smooth"
        >
          <li className="cursor-pointer text-Inter text-xs font-[400] bg-gray-200 select-none px-2 py-1.5 hover:bg-gray-200">
            Please select a field
          </li>
          {result[0].map((item, index) => (
            <li
              key={index}
              onClick={(e) => {
                setCsvMapIndex({
                  ...csvMapIndex,
                  [value]: Number(index),
                });
                document
                  .getElementById(`map_${value}_dropdown`)
                  .classList.toggle("hidden");
              }}
              className="cursor-pointer text-Inter text-sm font-[400] select-none p-2 hover:bg-slate-100"
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MapperDropdown);
