const initState = {
  runs: [],
  enrichmentDetails: null,
  enrichmentResults: [],
  totalRows: 0,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "Set_Runs":
      return {
        ...state,
        runs: action.payload,
      };
    case "Set_Enrichment_Details":
      return {
        ...state,
        enrichmentDetails: action.payload,
      };
    case "Set_Enrichment_Results":
      return {
        ...state,
        enrichmentResults: action.payload.results,
        totalRows: action.payload.total_count,
      };
    case 'UPDATE_ENRICHMENT_NAME':
      const { name, enrichmentId } = action.payload
      let tempEnrichmentDetails = state.enrichmentDetails

      if (enrichmentId === tempEnrichmentDetails.result._id) {
        tempEnrichmentDetails.result.task_name = name
      }

      let tempEnrichmentResults = state.enrichmentResults
      tempEnrichmentResults.map((enrich) => {
        if (enrich._id === enrichmentId) {
          enrich.task_name = name
        }
      })

      return {
        ...state,
        enrichmentResults: tempEnrichmentResults,
        enrichmentDetails: tempEnrichmentDetails
      }

    case "Reset_Enrichment_Details":
      return {
        ...state,
        enrichmentDetails: null,
      };
    default:
      return state;
  }
};
