import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';

export const PreviousRuns = (props) => {
  const { data, handlePageChange, loading, pageNumber, totalRowsCount } = props;
  const navigate = useNavigate();

  function convertDateTimeFormat(inputDateTime) {
    const inputDate = new Date(inputDateTime);
    const now = new Date();
    const Yesterday = new Date(now);
    Yesterday.setDate(Yesterday.getDate() - 1);

    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const dateOptions = { month: 'numeric', day: 'numeric', year: 'numeric' };

    if (inputDate.toDateString() === now.toDateString()) {
      return "Today, " + inputDate.toLocaleTimeString(undefined, timeOptions);
    } else if (inputDate.toDateString() === Yesterday.toDateString()) {
      return "Yesterday," + inputDate.toLocaleTimeString(undefined, timeOptions);
    } else {
      return inputDate.toLocaleDateString(undefined, dateOptions) + ", " + inputDate.toLocaleTimeString(undefined, timeOptions);
    }
  }

  const columns = [
    {
      field: "Name",
      headerName: "Run Name",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate capitalize">
            {params.row && params.row.task_name ? params.row.task_name : `-`}
          </div>
        );
      },
    },
    {
      field: "Rows",
      headerName: "Rows",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] capitalize">
            {params.row && params.row.rows ? params.row.rows : `-`}
          </div>
        );
      },
    },
    {
      field: "Cost",
      headerName: "Credit Cost",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] capitalize">
            {params.row && params.row.credit_cost
              ? params.row.credit_cost
              : `-`}
          </div>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        switch (params.row && params.row?.task_status) {
          case "COMPLETED":
            return (
              <Tippy content={params.row?.task_status} arrow={true}>
                <div className="font-[400] font-Inter text-lg text-[#000000de]">
                  {params.row && '✅'}
                </div>
              </Tippy>
            );
          case "CANCELLED":
            return (
              <Tippy content={params.row?.task_status} arrow={true}>
                <div className="font-[400] font-Inter text-lg text-[#000000de]">
                  {params.row && '⛔'}
                </div>
              </Tippy>
            );
          case "FAILED":
            return (
              <Tippy content={params.row?.task_status} arrow={true}>
                <div className="font-[400] font-Inter text-lg text-[#000000de]">
                  {params.row && '❌'}
                </div>
              </Tippy>
            );
          case "PENDING":
            return (
              <Tippy content={params.row?.task_status} arrow={true}>
                <div className="font-[400] font-Inter text-lg text-[#000000de]">
                  {params.row && '🟡'}
                </div>
              </Tippy>
            );
          case "PROCESSING":
            return (
              <Tippy content={params.row?.task_status} arrow={true}>
                <div className="font-[400] font-Inter text-lg text-[#000000de]">
                  {params.row && '⚙️'}
                </div>
              </Tippy>
            );
          default:
            return (
              <div className="font-[400] font-Inter text-[13.5px] text-[#000000de]">
                {params.row && params.row.task_status}
              </div>
            );
        }
      },
    },
    {
      field: "Time",
      headerName: "Updated at",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] lowercase">
            {params.row && params.row.updatedAt
              ? convertDateTimeFormat(params.row.updatedAt)
              : `-`}
          </div>
        );
      },
    },
  ];


  React.useEffect(() => {
    const paragraphs = document.querySelectorAll('.fade-in');
    paragraphs.forEach((p) => {
      p.classList.add('animate-fade-in');
    });
  }, []);

  return (
    <div className="w-full px-8 fade-in">
      <div className="w-full px-5 pt-3 pb-1.5 border-[1px] border-[#EAECF0] rounded-lg">
        <div className="flex item-center justify-between">
          <div className="">
            <p className="text-lg text-secondary font-semibold text-Inter">
              Previous Runs
            </p>
            <p className="text-sm text-secondarySupport text-Inter">
              History of all of your previous runs.
            </p>
          </div>
        </div>

        <div style={{ height: 370, width: "100%" }} className="w-full mt-5">
          <DataGrid
            sx={{
              overflowX: 'scroll',
              border: 0,
              "& .css-17jjc08-MuiDataGrid-footerContainer": {
                borderTop: 0,
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "500",
                fontSize: "13px",
                lineHeight: "18px",
                fontFamily: "Inter",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "1.15rem",
              },
              "& .MuiDataGrid-iconSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-selectedRowCount": {
                visibility: "hidden",
              }
            }}
            rows={data}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 20 } },
            }}
            loading={loading}
            paginationMode="server"
            rowCount={totalRowsCount}
            onRowClick={(params, event) => {
              navigate(`/enrichment-details/${params.row._id}`);
            }}
            onPaginationModelChange={handlePageChange}
            getRowId={(row) => row._id}
          />
        </div>

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PreviousRuns);
