import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getEnrichmentById,
  getEnrichmentResultById,
  cancelEnrichment,
  renameEnrichment,
} from "../../redux/actions/enrichmentAction";
import { useLocation, useNavigate } from "react-router-dom";
import { getDataLakeList } from "../../redux/actions/dataLakeAction";
import { BsArrowLeft } from "react-icons/bs";
import TaskInfo from "../../components/Enrichment/TaskInfo";
import Table from "../../components/Enrichment/Table";

const EnrichmentDetailsNew = (props) => {
  const {
    getEnrichmentById,
    enrichmentDetails,
    getEnrichmentResultById,
    enrichmentResults,
    cancelEnrichment,
    getDataLakeList,
    totalRows,
    renameEnrichment,
    user,
  } = props;
  const location = useLocation();
  const currentRoute = location.pathname.split("/")[2];
  const [renameModal, setRenameModal] = useState({
    status: false,
    name: null,
  });
  const [downloadingResults, setDownloadingResults] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleRename = async () => {
    try {
      await renameEnrichment(enrichmentDetails.result._id, renameModal.name);
    } finally {
      setRenameModal({
        status: false,
        name: null,
      });
    }
  };

  // console.log(enrichmentResults);

  async function fetchData(
    paginationModal = {},
    sortModel = [],
    filterModel = {}
  ) {
    // console.log("fetching data", sortModel);
    if (currentRoute.length > 0) {
      // console.log("fetching data", currentRoute);
      setLoading(true);
      await getEnrichmentResultById(
        currentRoute,
        paginationModal,
        sortModel,
        filterModel
      );
      setLoading(false);
    }
  }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (currentRoute.length <= 0) {
          navigate("/enrichment");
        }
        await getEnrichmentById(currentRoute);
        // getDataLakeList("all");
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    getDataLakeList("all");
  }, []);

  let topContent = (
    <div class="flex animate-pulse space-x-4 w-full">
      <div class="flex-1 space-y-2 py-1">
        <div class="h-7 w-4/12 rounded bg-slate-400"></div>
      </div>
    </div>
  );
  if (enrichmentDetails) {
    topContent = (
      <div className="flex w-full items-center -mt-2.5 gap-x-3">
        {renameModal.status ? (
          <div className="rounded-lg mt-2.5 w-full ">
            <input
              className="border-[2px] border-slate-200 w-full rounded-lg px-2.5 shadow-sm outline-none capitalize  font-semibold font-Inter text-[#101828] text-2xl"
              type="text"
              name="enrichment Name"
              id="enrichment Name"
              value={renameModal.name}
              onChange={(e) => {
                setRenameModal({
                  ...renameModal,
                  name: e.target.value,
                });
              }}
              placeholder="Provide Lead Name"
              onBlur={() => {
                handleRename();
              }}
            />
          </div>
        ) : (
          <div className="flex items-center w-fit  justify-start mt-2.5">
            <BsArrowLeft
              onClick={() => {
                navigate("enrichment");
              }}
              className="h-5 w-5 my-auto mr-1 cursor-pointer"
            />
            <p className="text-2xl font-Inter font-[600] mt-[0.1px] text-[#101828] ease-in duration-300">
              {enrichmentDetails.result.task_name}
            </p>
          </div>
        )}
        {!renameModal.status && (
          <svg
            version="1.0"
            onClick={() => {
              setRenameModal({
                name: enrichmentDetails.result.task_name,
                status: !renameModal.status,
              });
            }}
            className="cursor-pointer mt-2"
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="30px"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M3720 4629 c-145 -22 -268 -78 -393 -179 -40 -32 -637 -624 -1329
-1316 l-1256 -1259 -15 -70 c-210 -975 -249 -1167 -243 -1200 9 -46 57 -101
103 -116 19 -6 54 -9 77 -6 30 4 983 207 1186 252 27 7 2526 2496 2609 2600
279 349 224 860 -121 1131 -169 132 -408 195 -618 163z m223 -324 c243 -51
410 -294 369 -537 -23 -139 -59 -193 -275 -413 l-192 -196 -340 341 -340 340
201 200 c174 174 209 204 266 229 110 50 197 60 311 36z m-688 -1736 c-198
-198 -628 -625 -955 -949 l-596 -588 -423 -91 c-233 -50 -425 -90 -427 -88 -1
1 38 194 88 427 l90 425 951 953 952 952 340 -340 340 -340 -360 -361z"
              />
            </g>
          </svg>
        )}
      </div>
    );
  }

  return (
    <div className="py-4 w-full min-h-screen relative overflow-hidden">
      <div className=" px-8 flex items-center justify-center  sm:items-stretch sm:justify-start">
        {topContent}
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-[13px]" />
      <div className="px-8 py-4">
        <TaskInfo enrichmentDetails={enrichmentDetails}></TaskInfo>

        <div
          id="entableparent"
          className="w-full px-5 py-3 mt-5 border-[1px] border-[#EAECF0] rounded-lg"
        >
          <Table
            totalRows={totalRows}
            fetchData={fetchData}
            loading={loading}
            data={enrichmentResults}
            task_id={currentRoute}
            task_name={enrichmentDetails?.result.task_name}
            created_at={enrichmentDetails?.result.createdAt}
          ></Table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  enrichmentDetails: state.enrichment.enrichmentDetails,
  enrichmentResults: state.enrichment.enrichmentResults,
  totalRows: state.enrichment.totalRows,
  user: state.global.user,
});

const mapDispatchToProps = {
  getEnrichmentById,
  getEnrichmentResultById,
  cancelEnrichment,
  getDataLakeList,
  renameEnrichment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnrichmentDetailsNew);
