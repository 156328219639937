import React, { useState } from "react";
import { connect } from "react-redux";

export const Temp = (props) => {
  const [filterData, setFilterData] = useState([
    {
      values: ["services", "software"],
    },
  ]);

  const [temp, setTemp] = useState([1, 2, 3]);
  // console.log(temp)
  return (
    <div>
      <pre>{JSON.stringify(filterData, null, 4)}</pre>

      <p
        className="bg-red-400 p-5"
        onClick={(e) => {
          setFilterData((prev) => prev[0].values.push(Math.random(1)));
        }}
      >
        Click
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Temp);
