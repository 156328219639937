import React from "react";
import { connect } from "react-redux";
import { addToDataLake } from "../../redux/actions/enrichmentAction";
import { successAlert, failAlert } from "../sweetAlert";
export const AddToListModal = (props) => {
  const {
    dataLakeList,
    setAddToListModal,
    currentRoute,
    addToDataLake,
    user,
    stripe,
    totalLeadlists,
  } = props;
  const [itemSelected, setItemSelected] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleAddToList = async () => {
    try {
      setLoading(true);
      const response = await addToDataLake(itemSelected, currentRoute);

      if (response) {
        successAlert({
          title: "List Added Successfully",
        });
      } else {
        failAlert({
          title: "Something went wrong",
        });
      }
    } finally {
      setAddToListModal(false);
      setLoading(false);
    }
  };

  const getRemamingLeadLimit = () => {
    // if (stripe) {
    //     let limit = 0;

    //     switch (stripe?.plan) {
    //         case "SOLO_PLAN":
    //             limit = 25000;
    //             break;
    //         case "AGENCY_PLAN":
    //             limit = 150000;
    //             break;
    //         case "GROWTH_PLAN":
    //             limit = 500000;
    //             break;
    //         default:
    //             limit = 0;
    //             break;
    //     }

    //     return limit - totalLeadlists;
    // }
    if (user) {
      return user.current_leads_limit - user.total_leads_used;
    } else {
      return "loading...";
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50">
      <div className={`bg-[#fff] rounded-md px-8 py-5 relative w-1/3`}>
        <p className="text-lg text-secondary font-semibold text-Inter">
          {"Choice a List Name"}
        </p>
        <p className="text-sm text-secondarySupport text-Inter">
          {
            "If you want to create a new list you can do it by creating a list in Lead Hub"
          }
        </p>

        <div className={`flex justify-start items-center mt-3 px-5`}>
          <p className="font-Inter w-5/12 text-[#101828] font-semibold text-sm ">
            List Name: <span className="text-red-600"> *</span>
          </p>
          <select
            id="small"
            name="email"
            onChange={(e) => {
              setItemSelected(e.target.value);
            }}
            className="py-2.5 block w-7/12 px-3  truncate text-sm text-[#101828] border border-[#101828] border-opacity-40 rounded-lg bg-transparent"
            required
          >
            <option selected disabled className="truncate">
              Please select a field
            </option>
            {dataLakeList.map((item, index) => (
              <option value={item._id} className="truncate">
                {item.name}
              </option>
            ))}
          </select>
        </div>
        {/* <p className="text-xs mt-1 px-5 text-right">
                    Your remaining plan limit is{" "}
                    <span className="font-semibold">
                        {" "}
                        {getRemamingLeadLimit()}
                    </span>{" "}
                    Leads.
                </p> */}

        <div className="flex item-center justify-evenly gap-x-5 mt-6">
          <button
            onClick={() => {
              setAddToListModal(false);
            }}
            className="px-4 py-2 w-full text-sm text-Inter font-[500] rounded-lg border-[1px] border-gray-400"
          >
            Cancel
          </button>
          {loading ? (
            <button
              disabled
              type="button"
              className="px-4 py-2 w-full text-sm text-Inter bg-primary text-white font-[500] rounded-lg border-[1px] border-primary"
            >
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Loading...
            </button>
          ) : (
            <button
              onClick={() => {
                handleAddToList();
              }}
              className="px-4 py-2 w-full text-sm text-Inter bg-primary text-white font-[500] rounded-lg border-[1px] border-primary"
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dataLakeList: state.dataLake.dataLakeList,
  user: state.global.user,
  stripe: state.global.stripe,
  totalLeadlists: state.global.totalLeadlists,
});

const mapDispatchToProps = { addToDataLake };

export default connect(mapStateToProps, mapDispatchToProps)(AddToListModal);
