import instance from "./AxiosInstance/authenticated";

export const handleSubscription = (planId) => async (dispatch) => {
  try {
    const res = await instance.post(`/stripe/create-stripe-checkout-session`, {
      planId,
    });

    if (res.status == 200) {
      // console.log(res.data.data.sessionId);
      return {
        status: true,
        sessionId: res.data.data.sessionId,
      };
    }
    return {
      status: false,
    };
  } catch (error) {
    console.log(error);
    console.log("Error in creating stripe checkout session");
    return {
      status: false,
    };
  }
};

export const stripeCustomerPortal = () => async (dispatch) => {
  try {
    const res = await instance.get(
      `${process.env.REACT_APP_BASE_URL}/stripe/customer-portal`
    );

    if (res.status == 200) {
      return {
        status: true,
        portal: res.data.portal,
        return_url: res.data.return_url,
      };
    }
    return { status: false };
  } catch (error) {
    console.log(error, "in stripe customer portal");
    return {
      status: false,
    };
  }
};

export const updateStripe = (sessionId) => async (dispatch) => {
  try {
    const res = await instance.post(
      `${process.env.REACT_APP_BASE_URL}/stripe/update`,
      { sessionId }
    );
    if (res.status === 200) {
      dispatch({
        type: "UPDATE_STRIPE",
        payload: res.data,
      });
      return {
        status: true,
      };
    }
    return {
      status: false,
    };
  } catch (error) {
    return {
      status: false,
    };
  }
};

export const getUserBillingInfo = (sessionId) => async (dispatch) => {
  try {
    const res = await instance.get(
      `${process.env.REACT_APP_BASE_URL}/stripe/billing-info`
    );
    // console.log(res);
    dispatch({
      type: "GET_STRIPE_BILLING",
      payload: res.data,
    });
    return {
      status: true,
    };
  } catch (error) {
    return {
      status: false,
    };
  }
};
