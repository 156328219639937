import React from "react";

function Modal(props) {
  const { handleClose } = props;

  React.useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  return (
    <div className="fixed inset-0 bg-black fade-in bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50">
      <div
        className={`bg-[#fff] rounded-md fade-in px-8 py-5 relative max-w-[90vw] ${
          props.width ? props.width : `w-1/3`
        }`}
      >
        {props.children}
      </div>
    </div>
  );
}

export default Modal;
