const initState = {
  dataLakeList: [],
  dataLakeListDetail: [],
  listInfo: null,
  totalCount: null,
  allRecords: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case "Set_DataLake_List":
      return {
        ...state,
        dataLakeList: action.payload,
      };
    case "Set_DataLake_List_Detail":
      return {
        ...state,
        dataLakeListDetail: action.payload.result,
        listInfo: action.payload.listDetails,
        totalCount: action.payload.totalCount
          ? Number(action.payload.totalCount)
          : 0,
      };
    case "Set_DataLake_List_Detail_For_Query_Run":
      return {
        ...state,
        dataLakeListDetail: action.payload.results,
        totalCount: action.payload.totalCount
          ? Number(action.payload.totalCount)
          : 0,
      };
    case "Set_All_Records":
      return {
        ...state,
        allRecords: action.payload,
      };
    case 'UPDATE_DATALAKE_NAME':
      const { name, datalakeId } = action.payload
      let tempDetails = state.listInfo
      tempDetails.name = name
      return {
        ...state,
        listInfo: tempDetails
      };
    default:
      return state;
  }
};
