import React from "react";

const options = {
  day: "numeric",
  month: "long",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const getTaskStatus = (enrichmentDetails) => {
  // console.log(enrichmentDetails.result.task_status);
  if (
    enrichmentDetails.result.task_status == "cancelled" ||
    enrichmentDetails.result.task_status == "failed"
  ) {
    return (
      <p className="text-sm   text-secondary font-semibold text-Inter">
        {enrichmentDetails.result.task_status == "CANCELLED"
          ? "⛔ CANCELLED"
          : "❌ FAILED"}
      </p>
    );
  }
  if (enrichmentDetails.result.task_status == "completed") {
    return (
      <p className="text-sm   text-secondary font-semibold text-Inter">
        ✅ COMPLETED
      </p>
    );
  }
  if (enrichmentDetails.result.task_status == "pending") {
    return (
      <p className="text-sm   text-secondary font-semibold text-Inter">
        🟡 PENDING
      </p>
    );
  }
  if (enrichmentDetails.result.task_status == "processing") {
    return (
      <p className="text-sm   text-secondary font-semibold text-Inter">
        ⏳ PROCESSING
      </p>
    );
  }
};

const TaskInfo = ({ enrichmentDetails }) => {
  // console.log(enrichmentDetails);
  return (
    <div className="w-full px-5 py-3 border-[1px] border-[#EAECF0] rounded-lg">
      <div className="flex item-center justify-between">
        <div className="">
          <p className="text-lg text-secondary font-semibold text-Inter">
            Run Info
          </p>
          <p className="text-sm text-secondarySupport text-Inter">
            Information about the uploaded CSV.
          </p>
        </div>
      </div>
      <div className="mt-4 space-y-4">
        <div className="grid grid-cols-12 h-fit">
          <p className="text-sm col-span-3 text-secondarySupport text-Inter font-[500]">
            Number of orginal rows
          </p>
          {enrichmentDetails ? (
            <p className="text-sm col-span-9 text-secondary font-semibold text-Inter">
              {enrichmentDetails.result.rows}
            </p>
          ) : (
            <div class="flex animate-pulse space-x-4 col-span-9">
              <div class="flex-1 space-y-2 py-1">
                <div class="h-5 w-5/12 rounded bg-slate-400"></div>
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-12 h-fit">
          <p className="text-sm col-span-3 text-secondarySupport text-Inter font-[500]">
            Rows Enriched
          </p>
          {enrichmentDetails ? (
            <p className="text-sm col-span-9 text-secondary font-semibold text-Inter">
              {enrichmentDetails.eta.documents_completed}
            </p>
          ) : (
            <div class="flex animate-pulse space-x-4 col-span-9">
              <div class="flex-1 space-y-2 py-1">
                <div class="h-5 w-5/12 rounded bg-slate-400"></div>
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-12 -mt-10">
          <p className="text-sm col-span-3 text-secondarySupport text-Inter font-[500]">
            Started at
          </p>
          {enrichmentDetails ? (
            <p className="text-sm  col-span-9 text-secondary font-semibold text-Inter">
              {new Date(enrichmentDetails.result.createdAt).toLocaleDateString(
                "en-US",
                options
              )}
            </p>
          ) : (
            <div class="flex animate-pulse space-x-4 col-span-9">
              <div class="flex-1 space-y-2 py-1">
                <div class="h-5 w-5/12 rounded bg-slate-400"></div>
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-12 h-fit">
          <p className="text-sm col-span-3 text-secondarySupport text-Inter font-[500]">
            Credits Used
          </p>
          {enrichmentDetails ? (
            <p className="text-sm col-span-9 text-secondary font-semibold text-Inter">
              {enrichmentDetails.result.credit_cost}
            </p>
          ) : (
            <div class="flex animate-pulse space-x-4 col-span-9">
              <div class="flex-1 space-y-2 py-1">
                <div class="h-5 w-5/12 rounded bg-slate-400"></div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="grid grid-cols-12 h-fit">
          <p className="text-sm col-span-3 text-secondarySupport text-Inter font-[500]">
            Waterfall Enrihcments Used
          </p>
          {enrichmentDetails ? (
            <p className="text-sm col-span-9 text-secondary font-semibold text-Inter">
              {enrichmentDetails.result.waterfallEnrichments}
            </p>
          ) : (
            <div class="flex animate-pulse space-x-4 col-span-9">
              <div class="flex-1 space-y-2 py-1">
                <div class="h-5 w-5/12 rounded bg-slate-400"></div>
              </div>
            </div>
          )}
        </div> */}
        <div className="w-full">
          <div className="mt-1 flex items-center justify-start w-full">
            {enrichmentDetails ? (
              <div className="flex items-center gap-x-4">
                <p className="text-sm  text-secondarySupport text-Inter font-[500]">
                  Task Status
                </p>
                {getTaskStatus(enrichmentDetails)}
              </div>
            ) : (
              <div class="flex animate-pulse space-x-4 w-full">
                <div class="grid grid-cols-5 gap-x-3 w-full py-1">
                  <div class="h-5 w-full rounded bg-slate-400"></div>
                  <div class="h-5 w-full rounded bg-slate-400"></div>
                  <div class="h-5 w-full rounded bg-slate-400"></div>
                  <div class="h-5 w-full rounded bg-slate-400"></div>
                  <div class="h-5 w-full rounded bg-slate-400"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskInfo;
