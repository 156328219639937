import React, { useEffect, useRef, useState } from "react";
import TagInput from "../../components/TagInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useCSVReader } from "react-papaparse";
import { BsArrowLeft } from "react-icons/bs";
import MapperDropdown from "../../components/MapperDropdown";
import {
  BsPersonCircle,
  BsFacebook,
  BsTiktok,
  BsLinkedin,
  BsInstagram,
} from "react-icons/bs";
import { MdOutlineCorporateFare } from "react-icons/md";
import { ImBlog } from "react-icons/im";
import chroma from "chroma-js";
import { json2csv } from "json-2-csv";
import {
  getDataLakeListDetails,
  getDataLakeList,
  runQuery,
  uploadFile,
  filterSingleDatalakeList,
  renamDatalake,
} from "../../redux/actions/dataLakeAction";
import { connect } from "react-redux";
import Table from "./Table";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import { downloadFile } from "../../components/utils";
import makeAnimated from "react-select/animated";
import Modal from "../../components/Modal";
import { toast } from "sonner";
import personalDetails from "./data";

export const DatalakeDetails = (props) => {
  const {
    getDataLakeListDetails,
    dataLakeListDetail,
    listInfo,
    totalCount,
    runQuery,
    uploadFile,
    filterSingleDatalakeList,
    renamDatalake,
    stripe,
    totalLeadlists,
  } = props;

  const [tableData, setTableData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [fileUploadLoading, setFileUploadLoading] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname.split("/")[2];
  const [showModal, setShowModal] = React.useState(false);
  const { CSVReader } = useCSVReader();
  const [result, setResult] = useState(null);
  const [filterMode, setFilterMode] = useState(null);
  const [renameModal, setRenameModal] = useState({
    status: false,
    name: null,
  });

  const combineValuesIntoState = (...arrays) => {
    return arrays.reduce((acc, currArray) => {
      return {
        ...acc,
        ...currArray.reduce((innerAcc, currItem) => {
          return { ...innerAcc, [currItem.value]: null };
        }, {}),
      };
    }, {});
  };

  const [csvMapIndex, setCsvMapIndex] = useState(
    combineValuesIntoState(personalDetails)
  );

  const isSimilar = (str1, str2) => {
    // Convert both strings to lowercase for a case-insensitive comparison
    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();

    // Check for direct matches
    if (str1 === str2) return true;

    // Check for common variations
    const variations = {
      website: [
        "web",
        "site",
        "homepage",
        "domain",
        "url",
        "organization_primary_domain",
        "website",
        "link",
      ],
      email: ["e-mail", "mail", "emailaddress", "contactmail", "email"],
      company: ["firm", "business", "corp", "corporation", "enterprise"],
      first_name: [
        "firstname",
        "fname",
        "givenname",
        "namefirst",
        "name",
        "first name",
      ],
      last_name: [
        "lastname",
        "lname",
        "surname",
        "namelast",
        "familyname",
        "last name",
      ],
      title: ["designation", "position", "role", "jobtitle", "job"],
      seniority: ["rank", "status", "level"],
      department: ["dept", "section", "division", "unit"],
      phone: ["phonenumber", "mobile", "tel", "cell", "contactnumber"],
      number_of_employees: ["employee_count", "staffcount", "teamsize"],
      industry: ["sector", "field", "businessarea", "market"],
      facebook_url: [
        "facebook",
        "fb",
        "facebookpage",
        "fb_url",
        "facebook url",
      ],
      instagram_url: [
        "instagram",
        "insta",
        "ig",
        "instagrampage",
        "ig_url",
        "instagram url",
      ],
      tiktok_url: ["tiktok", "tt", "tiktokpage", "tt_url", "tiktok url"],
      linkedin_url: [
        "linkedin",
        "li",
        "linkedinpage",
        "li_url",
        "company linkedin url",
      ],
      personal_linkedin_url: [
        "personal_linkedin",
        "personal_li",
        "private_li",
        "personal_li_url",
        "person linkedin url",
      ],
      twitter_url: ["twitter", "tw", "twitterpage", "tw_url", "twitter url"],
      city: ["location_city", "town", "urban", "city"],
      state: ["province", "region", "location_state", "territory", "state"],
      country: ["nation", "location_country", "country"],
      description: ["desc", "about", "info", "summary", "overview"],
    };

    for (let key in variations) {
      if (
        (key === str1 && variations[key].includes(str2)) ||
        (key === str2 && variations[key].includes(str1))
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (result?.length > 0) {
      var array = result[0];
      // console.log(array, 'testing')
      const newCsvMapIndex = { ...csvMapIndex };
      for (let i = 0; i < array.length; i++) {
        for (let key in newCsvMapIndex) {
          if (isSimilar(key, array[i])) {
            newCsvMapIndex[key] = i;
            break;
          }
        }
      }
      setCsvMapIndex(newCsvMapIndex);
    }
  }, [result]);

  const handleOpenCSVReader = (res, fileInfo) => {
    // res.data.pop();
    // console.log(res.data, "res.data");
    //limit the upload limit to 5000 rows
    if (res.data.length > 5000) {
      toast.success("You can upload only 5000 rows at a time");
      return;
    }

    setResult(res.data.slice(0, 5000));
  };

  useEffect(() => {
    if (currentRoute.length === 0) {
      navigate("/data-lake");
    }
  }, [currentRoute]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    //to format the data for table component
    if (dataLakeListDetail.length > 0 && dataLakeListDetail[0].scrap_details) {
      // console.log(
      //   dataLakeListDetail.length > 0 && dataLakeListDetail[0].scrap_details,
      //   "dataLakeListDetail"
      // );
      var temp = [];
      dataLakeListDetail.map((item) => {
        temp.push(item.scrap_details);
      });
      setTableData(temp);
    } else {
      setTableData(dataLakeListDetail);
    }
  }, [dataLakeListDetail]);

  const getRemamingLeadLimit = () => {
    if (stripe) {
      let limit = 0;

      switch (stripe?.plan) {
        case "SOLO_PLAN":
          limit = 25000;
          break;
        case "AGENCY_PLAN":
          limit = 150000;
          break;
        case "GROWTH_PLAN":
          limit = 500000;
          break;
        default:
          limit = 0;
          break;
      }

      return limit - totalLeadlists;
    }
  };
  // console.log([personalDetails, companyDetails, facebookDetails, instagramDetails, tiktokDetails, linkedinDetails, blogDetails], "data for the map")
  // console.log(result, "csvMapIndex")
  const createCSV = async (data, csvMapIndex) => {
    const csvContent = [];
    // console.log(data, "data");
    let leadhubPlanLimit;

    if (result?.length > getRemamingLeadLimit()) {
      leadhubPlanLimit = getRemamingLeadLimit();
    } else {
      leadhubPlanLimit = data?.length - 1;
    }

    // Add the data rows
    for (let i = 1; i <= leadhubPlanLimit; i++) {
      var row = {};

      row["serial_number"] = i;
      var combinedArray = [...personalDetails];

      combinedArray.map((item) => {
        if (
          csvMapIndex[item.value] !== null &&
          data[i] !== null &&
          csvMapIndex[item.value] < data[i].length
        ) {
          if (item.value == "company") {
            row["Company"] = data[i][csvMapIndex[item.value]];
          } else if (item.value == "website") {
            row["Website"] = data[i][csvMapIndex[item.value]];
          } else {
            row[item.value] = data[i][csvMapIndex[item.value]];
          }
        } else {
          row[item.value] = " ";
        }
      });
      csvContent.push(row);
    }

    // console.log(csvContent, "csvContent");

    const csv = await json2csv(csvContent);
    // console.log(csv, "csvContent");

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    return blob;
  };

  const handleUpload = async () => {
    try {
      // setFileUploadLoading(true);
      const newCSVBlob = await createCSV(result, csvMapIndex);
      // return;

      // console.log(newCSVBlob, "newCSVBlob");
      const uploadStatus = await uploadFile(newCSVBlob, currentRoute);

      if (uploadStatus) {
        await getDataLakeListDetails(currentRoute);
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFileUploadLoading(false);
      setResult(null);
      setCsvMapIndex({
        website: null,
        email: null,
        company: null,
        first_name: null,
        last_name: null,
        title: null,
        seniority: null,
        department: null,
        phone: null,
        number_of_employees: null,
        industry: null,
        facebook_url: null,
        instagram_url: null,
        tiktok_url: null,
        linkedin_url: null,
        personal_linkedin_url: null,
        twitter_url: null,
        city: null,
        state: null,
        country: null,
        description: null,
      });
    }
  };

  async function fetchData(
    paginationModal = {},
    sortModel = [],
    filterModel = {}
  ) {
    try {
      setLoading(true);
      await getDataLakeListDetails(
        currentRoute,
        paginationModal,
        sortModel,
        filterModel
      );
    } catch (error) {
      console.log(error, "FetchDataOnFilter");
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);
  const handleRename = async () => {
    try {
      // console.log(listInfo)
      await renamDatalake(listInfo?._id, renameModal.name);
    } finally {
      setRenameModal({
        status: false,
        name: null,
      });
    }
  };

  return (
    <>
      <div className="py-4 w-full min-h-screen relative overflow-hidden fade-in">
        <div className="flex-1 px-8 flex items-center justify-center sm:items-stretch sm:justify-start">
          <p className="text-2xl font-Inter font-[600] fade-in mt-[0.1px] text-[#101828] ease-in duration-300">
            Lead Hub
          </p>
        </div>

        <hr className="bg-gray-200 h-[1.15px] mt-[13px]" />

        <div className="px-8 py-4 fade-in">
          <div
            id="dl-table-parent"
            className="w-full px-5 py-3 border-[1px] border-[#EAECF0] rounded-lg"
          >
            <Table
              data={tableData}
              totalRows={totalCount}
              loading={loading}
              fetchData={fetchData}
              setRenameModal={setRenameModal}
              handleRename={handleRename}
              renameModal={renameModal}
              showModal={() => {
                setShowModal(true);
              }}
              listInfo={listInfo}
              list_id={currentRoute}
            ></Table>
          </div>
        </div>
      </div>

      {showModal && (
        <Modal width={`fade-in ${!result ? `w-2/5` : `w-5/6`}`}>
          {!result && (
            <p className="text-lg text-secondary font-semibold text-Inter">
              Upload a file
            </p>
          )}{" "}
          <div className="w-full ">
            <CSVReader onUploadAccepted={handleOpenCSVReader}>
              {({ getRootProps, acceptedFile, getRemoveFileProps }) => (
                <>
                  {result && result.length > 0 ? (
                    <div className="px-4">
                      <p className="text-lg text-gray-700  font-bold text-Inter">
                        Feild Mapper
                      </p>
                      <p className="text-Inter text-gray-500 text-sm leading-snug">
                        Mapping all the feilds help to filter out data better.{" "}
                        <span className="font-semibold">{`(Scroll to map all the feilds)`}</span>
                      </p>
                      <hr className="text-slate-200 mt-2" />

                      <div className="overflow-y-scroll py-4 !h-[350px]">
                        <div>
                          <div className="flex items-center gap-x-3">
                            <BsPersonCircle className="h-6 w-6 text-gray-700" />
                            <p className="text-Inter text-lg font-semibold text-gray-700">
                              Details
                            </p>
                          </div>
                          <form
                            autoComplete="off"
                            className="grid grid-cols-3 gap-x-8 py-1"
                          >
                            {personalDetails.map((it, key) => (
                              <MapperDropdown
                                label={it.label}
                                value={it.value}
                                setCsvMapIndex={setCsvMapIndex}
                                csvMapIndex={csvMapIndex}
                                result={result}
                                key={key}
                              />
                            ))}
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      {...getRootProps()}
                      className="flex items-center justify-center w-full py-3"
                    >
                      <label
                        for="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-52 border-2 border-slate-300 border-dashed rounded-lg cursor-pointer bg-gray-100 dark:hover:bg-bray-800 hover:bg-gray-200"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            width="40"
                            height="40"
                            className="bg-[#F9FAFB] rounded-full p-2.5"
                            viewBox="0 0 20 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.66666 12.3333L10 9M10 9L13.3333 12.3333M10 9V16.5M16.6667 12.9524C17.6846 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2184 2.73736 10.212 1.5 7.91666 1.5C4.46488 1.5 1.66666 4.29822 1.66666 7.75C1.66666 9.47175 2.36287 11.0309 3.48912 12.1613"
                              stroke="#475467"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold text-primary">
                                Click to upload
                              </span>{" "}
                              or drag and drop
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              CSV (MAX. 25MB)
                            </p>
                          </>
                        </div>
                      </label>
                    </div>
                  )}
                </>
              )}
            </CSVReader>

            <div className="flex item-center justify-end gap-x-5 mt-1">
              <button
                onClick={() => {
                  setShowModal(false);
                  setResult(null);
                }}
                className="px-4 py-2 text-sm text-Inter font-[500] rounded-lg border-[1px] border-gray-400"
              >
                Cancel
              </button>

              {fileUploadLoading ? (
                <button
                  disabled
                  type="button"
                  className="font-base block rounded-lg bg-primary px-4 py-2 text-white"
                >
                  <svg
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Loading...
                </button>
              ) : (
                <button
                  onClick={() => {
                    handleUpload();
                  }}
                  className={`px-4 py-2 text-sm text-Inter bg-primary text-white font-[500] rounded-lg border-[1px] border-transparent ${
                    !(
                      csvMapIndex.email !== null &&
                      csvMapIndex.website !== null &&
                      csvMapIndex.company !== null
                    ) && `bg-opacity-70`
                  }`}
                  disabled={
                    !(
                      csvMapIndex.email !== null &&
                      csvMapIndex.website !== null &&
                      csvMapIndex.company !== null
                    )
                  }
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  dataLakeListDetail: state.dataLake.dataLakeListDetail,
  dataLakeList: state.dataLake.dataLakeList,
  listInfo: state.dataLake.listInfo,
  totalCount: state.dataLake.totalCount,
  stripe: state.global.stripe,
  totalLeadlists: state.global.totalLeadlists,
});

const mapDispatchToProps = {
  getDataLakeListDetails,
  getDataLakeList,
  runQuery,
  uploadFile,
  filterSingleDatalakeList,
  renamDatalake,
};

export default connect(mapStateToProps, mapDispatchToProps)(DatalakeDetails);
