import React, { useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

import ConfirmationModal from '../components/ConfirmationModal';

function Dashboard() {
  return (
    <div className='py-4 w-full min-h-screen relative overflow-hidden'>
      <div className="flex-1 px-8 flex items-center justify-center sm:items-stretch sm:justify-start">
        <p className='text-2xl font-Inter font-[600] mt-[0.1px] text-[#101828] ease-in duration-300'>Dashboard</p>
      </div>
    </div>
  )
}

export default Dashboard