import React from "react";
import { connect } from "react-redux";
import { Gauge } from "@ant-design/plots";
import { useNavigate } from "react-router-dom";
export const CreditsUsed = (props) => {
  const { currentCredits, total_purchased_credits, user } = props;
  const navigate = useNavigate();

  let current_credits = 100;
  let total_credits = 100;
  if (user) {
    current_credits = user.current_credits;
    total_credits = user.total_credits;
    if (!total_credits) {
      total_credits = currentCredits;
    }
  }

  let used = total_credits - current_credits;
  let perUsed = (used / total_credits) * 100;
  // console.log("perUsed", perUsed);

  const config = {
    percent: perUsed / 100,
    radius: 0.7,
    height: 180,
    range: {
      color: "#FF4405",
      width: 14,
    },
    // startAngle: Math.PI,
    // endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -10,
        style: {
          fontSize: "22px",
          color: "#4B535E",
        },
        formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      },
      style: {
        fontSize: 35,
      },
    },
    gaugeStyle: {
      lineCap: "round",
    },
  };

  // console.log("current_credits", current_credits, total_credits);

  return (
    <div className="w-1/2 px-5 py-3 border-[1px] border-[#EAECF0] rounded-lg">
      <div className="flex item-center justify-between">
        <div className="">
          <p className="text-lg text-secondary font-semibold text-Inter">
            Credits Used
          </p>
          <p className="text-sm text-secondarySupport text-Inter">{used}</p>
        </div>
      </div>
      <div className="relative mt-4">
        <Gauge {...config} />
      </div>
      <div className="">
        <p className="text-md text-secondary font-[500] text-Inter">
          Credits Used
        </p>
        <p className="text-sm text-secondarySupport text-Inter">
          You have used {perUsed}% of your available spots. Upgrade plan to run
          more enrichments.
        </p>
      </div>
      <button
        onClick={() => {
          navigate("/settings");
        }}
        className="px-4 py-2 mt-4 float-right text-sm text-Inter font-[500] flex items-center gap-x-3 rounded-lg border-[1px] border-gray-400"
      >
        <svg
          width="16"
          height="20"
          viewBox="0 0 16 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.83336 1.6665L1.41124 10.573C1.12057 10.9219 0.97523 11.0963 0.973009 11.2436C0.971078 11.3716 1.02814 11.4934 1.12774 11.5739C1.24232 11.6665 1.46934 11.6665 1.92339 11.6665H8.00003L7.16669 18.3332L14.5888 9.42663C14.8795 9.07782 15.0248 8.90341 15.027 8.75612C15.029 8.62807 14.9719 8.50625 14.8723 8.42576C14.7577 8.33317 14.5307 8.33317 14.0767 8.33317H8.00003L8.83336 1.6665Z"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Buy More
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentCredits: state.global.currentCredits,
  total_purchased_credits: state.global.total_purchased_credits,
  user: state.global.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreditsUsed);
