import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import TitleInput from "./TitleSearch";
import useTags from "./useTags";
import Swal from "sweetalert2";
import { json2csv } from "json-2-csv";
import { startANewEnrichment } from "../../redux/actions/enrichmentAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectKey from "./SelectKey";
import { CiCircleInfo } from "react-icons/ci";
import Tippy from "@tippyjs/react";

import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css";

const EnrichmentModal = ({
  handleCancel,
  result,
  csvMapIndex,
  mappingArray,
  startANewEnrichment,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [noOfRows, setNoOfRows] = useState(result.length - 1);
  const [appoloKeyLabel, setAppoloKeyLabel] = useState("");
  const [isWaterFall, setIsWaterFall] = useState(false);
  const [contactsPerCompany, setContactsPerCompany] = useState("");
  const [addEmails, setAddEmails] = useState(true);
  const [estimated_credits, setEstimatedCredits] = useState(0);
  const navigate = useNavigate();

  const { tags, handleAddTag, handleRemoveTag } = useTags();

  const [titles, setTitles] = useState([]);
  const [excludeTitles, setExcludeTitles] = useState([]);

  const createCsv = async (withBlob = true) => {
    try {
      const data = result;

      // console.log(data, "data");
      const csvContent = [];

      // // Add the data rows
      // let avRows = null;
      // if (feasibleRows) {
      //   avRows = feasibleRows;
      // } else {
      //   avRows = inputData.numberOfRows;
      // }
      // console.log(data[data.length - 1]);

      let mappedIndexes = [];
      // console.log(csvMapIndex, "csvMapIndex");
      for (let key in csvMapIndex) {
        if (csvMapIndex[key] || csvMapIndex[key] === 0) {
          mappedIndexes.push(parseInt(csvMapIndex[key]));
        }
      }
      // console.log(mappedIndexes, "mappedIndexes");
      let unmappedIndexes = [];
      for (let i = 0; i < data[0].length; i++) {
        if (!mappedIndexes.includes(i)) {
          unmappedIndexes.push(i);
        }
      }
      // console.log(unmappedIndexes, "unmappedIndexes");
      for (let i = 1; i < data.length; i++) {
        // check if all data[i] is empty
        let c = 0;
        for (let j = 0; j < data[i].length; j++) {
          if (data[i][j] === "") {
            c++;
          }
        }

        if (c != data[i].length) {
          // console.log(data[i]);
          const row = {};

          if (data[i].length >= 1) {
            row["serial_number"] = i;
            mappingArray.map((item) => {
              if (
                csvMapIndex[item.value] !== null &&
                csvMapIndex[item.value] < data[i].length
              ) {
                if (item.value == "company") {
                  row["organization_name"] = data[i][csvMapIndex[item.value]];
                } else if (item.value == "website") {
                  row["domain"] = data[i][csvMapIndex[item.value]];
                } else {
                  row[item.value] = data[i][csvMapIndex[item.value]];
                }
              } else {
                row[item.value] = " ";
              }
            });
            // console.log(row, "row");

            for (let j = 0; j < data[i].length; j++) {
              if (unmappedIndexes.includes(j)) {
                let curKey = data[0][j];
                // remove any special characters from key
                curKey = curKey.replace(/[^a-zA-Z0-9]/g, "");
                // remove any spaces from key
                curKey = curKey.replace(/\s/g, "");
                row[`extra_${data[0][j]}`] = data[i][j];
              }
            }
            // for (let j = 0; j < csvMapIdxKeys.length; j++) {
            //   if (!csvMapIndex[csvMapIdxKeys[j]]) {
            //     console.log(csvMapIdxKeys[j], "csvMapIdxKeys[j]");
            //   }
            // }
            // console.log(data[i], "data[i]");
            // console.log(data[0], "data[0]");
            csvContent.push(row);
          }
        }
      }

      let finalContent = [];
      let totalRows = noOfRows;
      // console.log(csvContent);
      if (totalRows > csvContent.length) {
        totalRows = csvContent.length;
      }
      for (let i = 0; i < totalRows; i++) {
        finalContent.push(csvContent[i]);
      }

      // console.log(finalContent, "finalContent");

      if (withBlob) {
        const csv = await json2csv(finalContent);

        let isCsvValid = true;
        for (let i = 0; i < finalContent.length; i++) {
          if (!finalContent[i].domain) {
            // console.log(finalContent[i], "finalContent[i]");
            isCsvValid = false;
            break;
          }
        }

        // console.log(csv, "csvContent");

        // console.log(csv, "csvContent");

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        return {
          status: true,
          blob: blob,
          finalContent: finalContent,
        };
      } else {
        return {
          status: true,
          finalContent: finalContent,
        };
      }
    } catch (e) {
      console.log(e, "error");
      return {
        status: false,
      };
    }
  };

  useEffect(() => {
    let setCredits = async () => {
      let csvResult = await createCsv(false);
      if (
        csvResult.status &&
        csvResult.finalContent &&
        csvResult.finalContent.length > 0
      ) {
        setEstimatedCredits(calcCredits(csvResult.finalContent));
      }
    };
    setCredits();
  }, [contactsPerCompany, noOfRows, addEmails, isWaterFall]);

  let isFieldValid = (field, type = "string") => {
    if (type == "string") {
      if (field && field != " " && field != "") {
        return true;
      }
      return false;
    }
  };

  const calcCredits = (finalContent) => {
    let rowsWithDomain = 0;
    let totalFinalRowsPossible = 0;
    for (let i = 0; i < finalContent.length; i++) {
      let curRow = finalContent[i];
      let isContact = false;
      if (isFieldValid(curRow.linkedin_url)) isContact = true;
      if (
        isFieldValid(curRow["last_name"] || curRow["first_name"]) &&
        isFieldValid(curRow["domain"])
      ) {
        isContact = true;
      }
      if (!isContact) rowsWithDomain++;
      if (isWaterFall) {
        if (isContact) totalFinalRowsPossible++;
        else if (contactsPerCompany)
          totalFinalRowsPossible += parseInt(contactsPerCompany);
      }
    }
    // console.log(rowsWithDomain, "rowsWithDomain");
    let estimated_credits_calc = rowsWithDomain; // for serach api
    if (isWaterFall) {
      estimated_credits_calc += totalFinalRowsPossible;
    }
    return estimated_credits_calc;
  };

  const handleStartEnrichment = async () => {
    try {
      if (name == "") {
        Swal.fire({
          text: "Enrichment name is required",
          icon: "error",
          allowOutsideClick: true,
        });
        return;
      }

      if (parseInt(noOfRows) < 1 || parseInt(noOfRows) > result.length - 1) {
        Swal.fire({
          text: "No of rows should be between 1 and " + (result.length - 1),
          icon: "error",
          allowOutsideClick: true,
        });
        return;
      }

      let csvResult = await createCsv();
      // console.log(csvResult.finalContent, "csvResult");
      // return;

      if (csvResult.status == false) {
        Swal.fire({
          text: "Each row should have atleast domain",
          icon: "error",
          allowOutsideClick: true,
        });
        return;
      }

      if (addEmails && !appoloKeyLabel) {
        Swal.fire({
          text: "Please provide a valid apollo key to add emails with apollo",
          icon: "error",
          allowOutsideClick: true,
        });
        return;
      }

      // console.log(csvResult.finalContent, "csvResult");

      let anyRowWithJustDomain = false;

      for (let i = 0; i < csvResult.finalContent.length; i++) {
        let curRow = csvResult.finalContent[i];
        let isContact = false;
        if (isFieldValid(curRow.linkedin_url)) isContact = true;
        if (
          isFieldValid(curRow["last_name"] || curRow["first_name"]) &&
          isFieldValid(curRow["domain"])
        ) {
          isContact = true;
        }
        if (!isContact) {
          anyRowWithJustDomain = true;
          break;
        }
      }
      // console.log(anyRowWithJustDomain, "anyRowWithJustDomain");
      if (anyRowWithJustDomain) {
        if (
          titles.length == 0 ||
          !contactsPerCompany ||
          contactsPerCompany == ""
        ) {
          Swal.fire({
            text: "Please add job titles and contacts per company for this csv",
            icon: "error",
            allowOutsideClick: true,
          });
          return;
        }
      }

      if (!user.isAdmin && user.runs == 0) {
        Swal.fire({
          text: "You have used all your runs please upgradde your plan",
          icon: "error",
          allowOutsideClick: true,
        });
        return;
      }

      let estimated_credits_calc = calcCredits(csvResult.finalContent);
      // console.log(estimated_credits_calc, "estimated_credits");s

      if (!user.isAdmin && user.current_credits == 0) {
        Swal.fire({
          text: "You have 0 credits please upgradde your plan",
          icon: "error",
          allowOutsideClick: true,
        });
        return;
      }

      // if (!user.isAdmin && user.current_credits < estimated_credits) {
      //   Swal.fire({
      //     text: `This enrichment have estimated ${estimated_credits_calc} and you have ${user.current_credits} credits`,
      //     icon: "error",
      //     allowOutsideClick: true,
      //   });
      //   return;
      // }

      setLoading(true);

      const { status, message, code } = await startANewEnrichment(
        csvResult.blob,
        name,
        noOfRows,
        appoloKeyLabel,
        isWaterFall,
        titles,
        contactsPerCompany,
        addEmails,
        excludeTitles
      );
      setLoading(false);
      if (code == 503) {
        Swal.fire({
          text: "You have reached maximum amount of concurrent scraps",
          icon: "error",
          allowOutsideClick: true,
        });
      } else {
        if (status) {
          navigate(`enrichment-details/${message}`);
        } else {
          handleCancel();
        }
      }

      // console.log(name, noOfRows, appoloKeyLabel, isWaterFall, useKey, tags);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal width="w-[630px]">
        <div className="px-1 relative">
          <div className="sticky top-0 z-10 bg-white">
            <div className="flex flex-row justify-between gap-x-5">
              <div className="flex flex-row item-center gap-x-5">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="4"
                    y="4"
                    width="48"
                    height="48"
                    rx="24"
                    fill="#D1FADF"
                  />
                  <path
                    d="M20.5 38V33M20.5 23V18M18 20.5H23M18 35.5H23M29 19L27.2658 23.5089C26.9838 24.2421 26.8428 24.6087 26.6235 24.9171C26.4292 25.1904 26.1904 25.4292 25.9171 25.6235C25.6087 25.8428 25.2421 25.9838 24.5089 26.2658L20 28L24.5089 29.7342C25.2421 30.0162 25.6087 30.1572 25.9171 30.3765C26.1904 30.5708 26.4292 30.8096 26.6235 31.0829C26.8428 31.3913 26.9838 31.7579 27.2658 32.4911L29 37L30.7342 32.4911C31.0162 31.7579 31.1572 31.3913 31.3765 31.0829C31.5708 30.8096 31.8096 30.5708 32.0829 30.3765C32.3913 30.1572 32.7579 30.0162 33.4911 29.7342L38 28L33.4911 26.2658C32.7579 25.9838 32.3913 25.8428 32.0829 25.6235C31.8096 25.4292 31.5708 25.1904 31.3765 24.9171C31.1572 24.6087 31.0162 24.2421 30.7342 23.5089L29 19Z"
                    stroke="#039855"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="4"
                    y="4"
                    width="48"
                    height="48"
                    rx="24"
                    stroke="#ECFDF3"
                    stroke-width="8"
                  />
                </svg>
                <div>
                  <div className="">
                    <p className="text-lg text-secondary font-semibold text-Inter">
                      Create an Enrichment
                    </p>
                    <p className="text-sm text-secondarySupport text-Inter">
                      Some basic information about your enrichment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-2"></hr>

            <div
              style={{
                maxHeight: "75vh",
                overflow: "auto",
              }}
            >
              <div>
                <div className="font-[500] text-md mt-4">
                  Enrichment Details
                </div>
                <div className="flex justify-between items-center gap-x-5 mt-1">
                  <div className="col-span-6">
                    <label
                      htmlFor="email"
                      className="text-[#4E5674] font-Inter text-xs ml-[1px]"
                    >
                      Enrichment Name
                    </label>
                    <div className=" rounded-lg border border-gray-300 py-2 px-4">
                      <input
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                        className="border-none outline-none w-full font-Inter"
                        type="text"
                        name="LeadName"
                        id="LeadName"
                        placeholder="e.g. Website design"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label
                      htmlFor="email"
                      className="text-[#4E5674] font-Inter text-xs ml-[1px]"
                    >
                      No of Rows
                    </label>
                    <div className="flex items-center rounded-md border border-gray-300 py-2 px-4 w-[120px] flex-row gap-2">
                      <input
                        onChange={(e) => {
                          if (e.target.value > result.length - 1) {
                            return;
                          }
                          setNoOfRows(e.target.value);
                        }}
                        value={noOfRows}
                        className="border-none outline-none w-full font-Inter"
                        type="number"
                        max={10}
                        name="NumberofRows"
                        id="NumberofRows"
                        placeholder="10000"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <label
                  htmlFor="email"
                  className="text-[#4E5674] font-Inter text-xs ml-[1px]"
                >
                  Apollo Key
                </label>
                <SelectKey
                  keys={user.appolo_keys}
                  updateAppoloKeyLabel={(label) => {
                    setAppoloKeyLabel(label);
                  }}
                  selectedLabel={appoloKeyLabel}
                ></SelectKey>
              </div>

              <hr className="mt-3"></hr>
              <div>
                <div className="flex flex-col mb-3">
                  <div className="font-[500] text-md mt-4">Contact Data</div>
                  <div className="text-sm text-gray-500">
                    Only add if no contact info was uploaded
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="w-full">
                    <label
                      htmlFor="email"
                      className="text-[#4E5674] font-Inter text-xs ml-[1px]"
                    >
                      Enter Job Titles
                    </label>

                    <TagsInput
                      value={titles}
                      onChange={(tags) => {
                        setTitles([...tags]);
                      }}
                      addOnPaste={true}
                      pasteSplit={(data) => {
                        if (data.includes(",")) {
                          return data.split(",").map((d) => d.trim());
                        } else {
                          return data.split(" ").map((d) => d.trim());
                        }
                      }}
                      addKeys={[13, 9, ","]}
                      onlyUnique={true}
                    ></TagsInput>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="email"
                      className="text-[#4E5674] font-Inter text-xs ml-[1px] flex flex-row items-center gap-1 mb-1"
                    >
                      Contacts Per Company (Amount of rows to return per domain)
                      {/* <span className="cursor-pointer">
                      <Tippy content="hi" arrow={true} zIndex={100000000000000}>
                        <CiCircleInfo fontSize={16}></CiCircleInfo>
                      </Tippy>
                    </span> */}
                    </label>
                    <div className="mb-4 flex items-center rounded-lg  border border-gray-300 py-2 px-3">
                      <input
                        onChange={(e) => {
                          setContactsPerCompany(e.target.value);
                        }}
                        value={contactsPerCompany}
                        className="border-none outline-none w-full font-Inter"
                        type="number"
                        name="LeadName"
                        id="LeadName"
                        placeholder="Contacts per company"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="w-full">
                    <label
                      htmlFor="email"
                      className="text-[#4E5674] font-Inter text-xs ml-[1px]"
                    >
                      Enter Job Title Keyword Exclusions
                    </label>

                    <TagsInput
                      value={excludeTitles}
                      onChange={(tags) => {
                        setExcludeTitles([...tags]);
                      }}
                      addOnPaste={true}
                      pasteSplit={(data) => {
                        if (data.includes(",")) {
                          return data.split(",").map((d) => d.trim());
                        } else {
                          return data.split(" ").map((d) => d.trim());
                        }
                      }}
                      addKeys={[13, 9, ","]}
                      onlyUnique={true}
                    ></TagsInput>
                  </div>
                </div>
              </div>
              <hr className="mt-1"></hr>
              {/* <SelectKey
              keys={user.appolo_keys}
              updateAppoloKeyLabel={(label) => {
                setAppoloKeyLabel(label);
              }}
              selectedLabel={appoloKeyLabel}
            ></SelectKey> */}
              <div className="flex align-middle gap-2 flex-row mt-2">
                <input
                  className="mt-1"
                  type="checkbox"
                  value={addEmails}
                  checked={addEmails}
                  onChange={(e) => {
                    setAddEmails(e.target.checked);
                  }}
                ></input>
                <span className="font-[500]">Add emails with Apollo</span>
              </div>
              <div className="flex align-middle gap-2 flex-row mt-2">
                <input
                  className="mt-1"
                  type="checkbox"
                  value={isWaterFall}
                  onChange={(e) => {
                    setIsWaterFall(e.target.checked);
                  }}
                ></input>
                <span className="font-[500]">
                  Add emails with waterfall (instant enrichment + 20-80% more
                  emails)
                </span>
              </div>
            </div>
          </div>

          <hr className="mt-3"></hr>
          <div>
            <div className="mt-3 w-full px-1 space-y-2 sticky bottom-0 bg-white">
              <div className="flex item-center justify-between ">
                <button
                  onClick={() => {
                    handleCancel();
                  }}
                  className="px-4 py-2 text-sm text-Inter font-[500] rounded-lg border-[1px] border-gray-400"
                >
                  Back
                </button>
                {loading ? (
                  <button
                    disabled
                    type="button"
                    className="px-4 py-2 text-sm text-Inter bg-primary text-white font-[500] rounded-lg border-[1px] border-primary"
                  >
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleStartEnrichment();
                    }}
                    className="px-4 py-2 text-sm text-Inter bg-primary text-white font-[500] rounded-lg border-[1px] border-primary"
                  >
                    Start Enrichment ( {estimated_credits} estimated max credits
                    )
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.global.user,
});

const mapDispatchToProps = { startANewEnrichment };

export default connect(mapStateToProps, mapDispatchToProps)(EnrichmentModal);
