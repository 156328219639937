import Select from "react-select";
import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Table from "./Table";
import makeAnimated from "react-select/animated";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
  getAllDataLakeList,
  queryAllDataLakeList,
  filterSingleDatalakeList,
} from "../../redux/actions/dataLakeAction";
export const ViewAllDataLake = (props) => {
  const {
    allRecords,
    getAllDataLakeList,
    queryAllDataLakeList,
    filterSingleDatalakeList,
  } = props;
  const [loading, setLoading] = React.useState(true);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const animatedComponents = makeAnimated();
  const [totalCount, setTotalCount] = React.useState(0);
  const isFirstRender = useRef(true);
  const [filterMode, setFilterMode] = useState(null);
  const navigate = useNavigate();
  //   async function fetchMoreData() {
  //     try {
  //       setLoading(true);
  //       if (selectedTags.length === 0) {
  //         const { status, totalCount } = await getAllDataLakeList(
  //           pageNumber + 1
  //         );
  //         if (status) {
  //           setTotalCount(totalCount);
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchMoreData();
  // }, [pageNumber, selectedTags]);

  // useEffect(() => {
  //   async function fetchMoreData() {
  //     try {
  //       setLoading(true);

  //       if (selectedTags.length === 1) {
  //         setPageNumber(0);
  //       }

  //       if (selectedTags.length > 0) {
  //         const { status, totalCount } = await queryAllDataLakeList(
  //           selectedTags,
  //           pageNumber + 1,
  //           null,
  //           filterMode,
  //           sortModel
  //         );
  //         if (status) {
  //           setTotalCount(totalCount);
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchMoreData();
  // }, [selectedTags]);

  async function fetchData(
    paginationModal = {},
    sortModel = [],
    filterModel = {}
  ) {
    try {
      // console.log(paginationModal);
      setLoading(true);

      let res = await getAllDataLakeList(
        paginationModal,
        sortModel,
        filterModel
      );

      if (res.status) {
        setTotalCount(res.totalCount);
      }
    } catch (error) {
      console.log(error, "FetchDataOnFilter");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="py-4 w-full min-h-screen relative overflow-hidden">
      <div className="flex-1 px-8 flex items-center justify-center sm:items-stretch sm:justify-start">
        <BsArrowLeft
          onClick={() => {
            navigate("lead-hub");
          }}
          className="h-5 w-5 my-auto mr-1 cursor-pointer"
        />
        <p className="text-2xl font-Inter font-[600] mt-[0.1px] text-[#101828] ease-in duration-300">
          Data Lake
        </p>
      </div>

      <hr className="bg-gray-200 h-[1.15px] mt-[13px]" />

      <div className="px-8 py-4 ">
        <div
          id="dl-table-parent"
          className="w-full px-5 py-3 border-[1px] border-[#EAECF0] rounded-lg"
        >
          <Table
            data={allRecords}
            totalRows={totalCount}
            loading={loading}
            fetchData={fetchData}
            viewAll={true}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allRecords: state.dataLake.allRecords,
});

const mapDispatchToProps = {
  getAllDataLakeList,
  queryAllDataLakeList,
  filterSingleDatalakeList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllDataLake);
