import instance from "./AxiosInstance/authenticated";

export const getUserInfo = () => async (dispatch) => {
  try {
    const { data, status } = await instance.get(`/user/get-user`);
    // console.log(data);
    if (status === 200) {
      dispatch({
        type: "Set_Current_Credits",
        payload: {
          current_credits: data.user.current_credits,
          total_purchased_credits: data.total_purchased_credits,
          user: data.user,
          stripeDetails: data.stripeDetails,
          totalLeadlists: data.totalLeadlists,
        },
      });
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signin");
    return false;
  }
};
