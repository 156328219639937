import React from 'react'
import { useNavigate } from 'react-router-dom';
function ResetPassword() {
    const navigate = useNavigate();
    return (
        <div className='grid place-content-center items-center h-screen w-full'>
            <div className='px-3 w-full'>
                <p className='font-Inter text-center text-md font-semibold text-2xl text-[#101828] mt-4'>Forgot password?</p>
                <p className='font-Inter text-center text-sm text-[#475467] mt-2'>No worries, we’ll send you reset instructions.</p>
                <div className="mt-5">
                    <label htmlFor="email" className="text-[#4E5674] font-[500] text-sm font-Inter">
                        Your email
                    </label>
                    <div className="mb-4 mt-1 flex items-center rounded-lg border-2 py-1.5 px-3">
                        <input className="border-none outline-none w-full" type="text" name="email" id="email" placeholder="Email Address" required />
                    </div>
                </div>
                <div className="mt-4">
                    <label htmlFor="password" className="text-[#4E5674] font-[500] font-Inter">
                        Password *
                    </label>
                    <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
                        <input className="border-none outline-none w-full font-Inter" type="password" name="password" id="password" placeholder='••••••••' required />
                    </div>
                </div>
                <div className='flex justify-center items-center gap-x-4 mt-5'>
                    <button  onClick={() => {navigate('/signin')}} type="text" className="text-[12px] text-sm w-full cursor-pointer font-semibold font-Inter px-5 rounded-lg py-2 text-white bg-[#2970FF] shadow-navButton hover:shadow-navButton border-[1px] border-[#2970FF] " >
                        Reset password
                    </button>
                </div>
                <p  onClick={() => {navigate('/signin')}} className='font-Inter cursor-pointer font-[500] text-sm text-[#475467] mt-5 flex items-center justify-center gap-x-3'>
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3332 7.00008H1.6665M1.6665 7.00008L7.49984 12.8334M1.6665 7.00008L7.49984 1.16675" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    Back to log in
                </p>
            </div>
        </div>
    )
}

export default ResetPassword