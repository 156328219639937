import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'

export const handleSignIn = ({ email, password }) => async (dispatch) => {
  try {
    const { data, status } = await instance.post("/user/login", {
      email: email,
      password: password,
    });
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Logged in successfully!")
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signin");
    if (error.response?.data?.message) {
      toast.error(error.response?.data?.message)
      return false
    }
    toast.error("Something went wrong")
    return false
  }
};

export const handleSignUp = ({ email, password, name }) => async (dispatch) => {
  try {
    const { data, status } = await instance.post("/user/signup", {
      email: email,
      password: password,
      fullName: name,
    });
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Account created successfully!")
      return true
    }
  } catch (error) {
    console.log(error, "Error in signin");
    if (error.response?.data?.message) {
      toast.error(error.response?.data.message)
      return false
    }
    toast.error("Something went wrong")
    return false
  }
};

export const getLoggedInUser = () => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get("/user/get-user");
    if (status === 200) {

      console.log(data, "data in get user");
      dispatch({
        type: "SET_USER",
        payload: data.data,
      });
      return data;
    }
  } catch (error) {
    console.log(error, "Error in getting user");
    return false;
  }
};
