import instance from "./AxiosInstance/authenticated";
import { toast } from "sonner";
export const getDataLakeList = (pageNumber) => async (dispatch) => {
  try {
    const { data, status } = await instance.get(
      `/datalake/all-list/${pageNumber}`
    );
    if (status === 200) {
      dispatch({
        type: "Set_DataLake_List",
        payload: data.result,
      });
      return {
        status: true,
        totalCount: data.total_list_count ? Number(data.total_list_count) : 0,
      };
    }

    return {
      status: false,
      totalCount: 0,
    };
  } catch (error) {
    console.log(error, "Error in signin");
    return {
      status: false,
      totalCount: 0,
    };
  }
};

export const getDataLakeListDetails =
  (id, paginationModal, sortModel, filterModel) => async (dispatch) => {
    try {
      const { data, status } = await instance.get(`/datalake/list`, {
        params: {
          list_id: id,
          paginationModal: paginationModal,
          sortModel: sortModel,
          filterModel: filterModel,
        },
      });
      if (status === 200) {
        dispatch({
          type: "Set_DataLake_List_Detail",
          payload: data,
        });
        return true;
      }
    } catch (error) {
      console.log(error, "Error in signin");
      return false;
    }
  };

export const createNewList = (name) => async (dispatch) => {
  try {
    const { status, data } = await instance.post(`/datalake/create-list`, name);
    if (status === 200) {
      return { status: true, list_id: data.list_id };
    }

    return { status: false, list_id: null };
  } catch (error) {
    console.log(error, "Error in signin");
    return { status: false, list_id: null };
  }
};

export const uploadFile = (file, list_id) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("list_id", list_id);
    const { status } = await instance.post(
      `/datalake/upload-to-list`,
      formData
    );
    if (status === 200) {
      return true;
    }

    return false;
  } catch (error) {
    console.log(error, "Error in signin");
    return false;
  }
};

export const runQuery =
  (query, page_number, datalake_id = null, filterMode = " ", sortModal = []) =>
  async (dispatch) => {
    try {
      console.log(
        query,
        page_number,
        datalake_id,
        "query, page_number, list_id"
      );
      const { status, data } = await instance.post(
        `/datalake/query-all-datalake`,
        {
          query,
          page_number,
          datalake_id,
          filterMode,
          sortModal: sortModelToUrlParam(sortModal),
        }
      );

      console.log(data);
      if (status === 200) {
        if (data.results.length === 0) {
          toast.error("No Result Found!");
        }

        dispatch({
          type: "Set_DataLake_List_Detail_For_Query_Run",
          payload: data,
        });
        return true;
      }

      return false;
    } catch (error) {
      console.log(error, "Error in signin");
      return false;
    }
  };

export const getAllDataLakeList =
  (paginationModal, sortModel, filterModel) => async (dispatch) => {
    try {
      console.log(paginationModal, sortModel, filterModel);
      const { data, status } = await instance.get(`/datalake/get-all-list`, {
        params: {
          paginationModal: paginationModal,
          sortModel: sortModel,
          filterModel: filterModel,
        },
      });
      if (status === 200) {
        dispatch({
          type: "Set_All_Records",
          payload: data.result,
        });
        return {
          status: true,
          totalCount: data.totalDocument ? Number(data.totalDocument) : 0,
        };
      }

      return {
        status: false,
        totalCount: 0,
      };
    } catch (error) {
      console.log(error, "Error in signin");
      return {
        status: false,
        totalCount: 0,
      };
    }
  };

export const queryAllDataLakeList =
  (query, pageNumber, datalake_id = null, filterMode = " ", sortModal = []) =>
  async (dispatch) => {
    try {
      const { data, status } = await instance.post(
        `/datalake/query-all-datalake`,
        {
          query,
          pageNumber,
          datalake_id,
          filterMode,
          sortModal: sortModelToUrlParam(sortModal),
        }
      );
      if (status === 200) {
        dispatch({
          type: "Set_All_Records",
          payload: data.results,
        });
        return {
          status: true,
          totalCount: data.totalCount ? Number(data.totalCount) : 0,
        };
      }

      return {
        status: false,
        totalCount: 0,
      };
    } catch (error) {
      console.log(error, "Error in signin");
      return {
        status: false,
        totalCount: 0,
      };
    }
  };

function sortModelToUrlParam(sortModel) {
  // console.log(sortModel, "sortModel");

  if (sortModel.length === 0) {
    return "first_name=asc";
  }

  return sortModel.map((item) => `${item.field}=${item.sort}`).join(",");
}

export const filterSingleDatalakeList =
  (taskId, pageNumber, filterMode = " ", sortModal = [], selectedTags = []) =>
  async (dispatch) => {
    try {
      const { data, status } = await instance.post(
        `/datalake/get-task-result/${taskId}/${pageNumber}/${filterMode}/${sortModelToUrlParam(
          sortModal
        )}`,
        {
          query: selectedTags,
        }
      );
      if (status === 200) {
        // console.log(taskId, "taskId");
        if (taskId) {
          dispatch({
            type: "Set_DataLake_List_Detail_For_Query_Run",
            payload: {
              results: data.results,
              totalCount: data.total_count ? Number(data.total_count) : 0,
            },
          });
        } else {
          dispatch({
            type: "Set_All_Records",
            payload: data.results,
          });
        }
        return {
          status: true,
          totalCount: data.total_count ? Number(data.total_count) : 0,
        };
      }
    } catch (error) {
      console.log(error, "Error in signin");
      return {
        status: false,
        totalCount: 0,
      };
    }
  };

export const renamDatalake = (datalakeId, name) => async (dispatch) => {
  try {
    // console.log(datalakeId, name);
    const { data, status } = await instance.post("/datalake/rename", {
      datalakeId: datalakeId,
      name: name,
    });
    // console.log("res status", status);
    if (status === 200) {
      toast.success("Enrichment named changed successfully!");
      dispatch({
        type: "UPDATE_DATALAKE_NAME",
        payload: {
          name: name,
          datalakeId: datalakeId,
        },
      });
    }
  } catch (error) {
    console.error(
      "[Error] something went wrong when updating the enrichment name",
      error
    );
  }
};
