import axios from "axios";
import { successAlert, failAlert } from "../../components/sweetAlert";
import instance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'


export const getSearchResult = (filterdata, pageNumber, recordID) => async (dispatch) => {
    try {
        const { data, status } = await instance.post("/search/search-companies", {
            query: filterdata,
            page: pageNumber
        });

        if (status === 200) {
            if (data.status) {
                var { result, message, currentSearchId } = data
                if (result.length === 0) {
                    toast.error("No results found")
                }
                dispatch({
                    type: 'SET_SEARCH_TABLE_DATA',
                    payload: result,
                });
                dispatch({
                    type: 'SET_CURRENT_SEARCH_ID',
                    payload: currentSearchId
                })

                if (recordID) {
                    dispatch({
                        type: 'SET_RECORD_ID',
                        payload: {
                            recordID: recordID,
                            data: result.companies
                        }
                    })
                    return result.companies
                }

            } else {
                toast.error(data.message)
            }
        }

    } catch (error) {
        console.log(error, "Error in signin");
        return false;
    }
};


export const getEmployees = (domainName, filterdata, pageNumber = 1, recordID) => async (dispatch) => {
    try {

        const { data, status } = await instance.post("/search/search-employees", {
            query: filterdata,
            page: pageNumber,
            domainName: domainName,
        });
        if (status === 200) {
            if (data.status) {
                var { result, message, currentSearchId } = data
                if (result.length === 0) {
                    toast.error("No results found")
                }
                dispatch({
                    type: "SET_EMPLOYEE_DATA",
                    payload: result
                })
                dispatch({
                    type: 'SET_CURRENT_SEARCH_ID',
                    payload: currentSearchId
                })

                if (recordID) {
                    dispatch({
                        type: 'SET_RECORD_ID',
                        payload: {
                            recordID: recordID,
                            data: result.employees
                        }
                    })
                    return result.employees
                }
            } else {
                toast.error(data.message)
            }
        }


        return false;
    } catch (error) {
        console.log(error, "Error in signin");
        return false;
    }
};

function convertMongooseDateTimeToDDMMYYYYTime(mongooseDateTime) {
    const date = new Date(mongooseDateTime);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year}-${hours}:${minutes}`;
}

export const download = (query, domainName, type, UUID = null) => async (dispatch) => {
    let loadingToast;
    try {
        loadingToast = toast.loading('Initializing download...');
        const { data, status } = await instance.post("/search/download", {
            query: query,
            domainName: domainName,
            type: type,
            UUID: UUID
        });

        if (status === 200) {
            const formattedDateTime = convertMongooseDateTimeToDDMMYYYYTime(
                new Date()
            );

            let filename = `result-${type}-${formattedDateTime}.csv`;

            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", filename);
            link.setAttribute("hidden", true);
            document.body.appendChild(link);
            link.click();

            // Close the loading toast when download is successful
            toast.dismiss(loadingToast);

            // Show a success toast
            toast.success(`Download successful`);
        }
        else {
            // Close the loading toast if there's an error
            toast.dismiss(loadingToast);

            // Show an error toast
            toast.error('Download failed. Please try again.');
        }

        return false;
    } catch (error) {
        console.error(error, "Error in download");

        // Close the loading toast if there's an error
        toast.dismiss(loadingToast);

        // Show an error toast
        toast.error('Download failed. Please try again.');
        return false;
    }
};

export const getSavedSearches = (pageNumber = 1) => async (dispatch) => {
    try {
        dispatch({
            type: 'SET_SAVED_SEARCH_LOADING',
            payload: true
        })
        const { data, status } = await instance.get("/search/get-all-saved-searches?page=" + pageNumber);
        if (status === 200) {
            var { result, message, totalSearchResult } = data
            dispatch({
                type: 'SET_SAVED_TABLE_RESULT',
                payload: result,
            });
            dispatch({
                type: 'SET_TOTAL_SAVED_RESULT',
                payload: totalSearchResult
            })
        }

    } catch (error) {
        console.log(error, "Error in signin");
    } finally {
        dispatch({
            type: 'SET_SAVED_SEARCH_LOADING',
            payload: false
        })
        return true
    }
}

export const runEmployeeSearch = (fileBlob, filterData) => async (dispatch) => {
    try {

        let { data, status } = await instance.post('/search/run-employee-search', {
            fileBlob: fileBlob,
            filterData: filterData
        })

        if (status === 200) {
            toast.success("Employee search started successfully")
            return true
        }
    } catch (error) {
        console.log(error)
        toast.error('Something went wrong!')
        return false
    }
}

