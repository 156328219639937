import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import {
  Authentication,
  ForgotPassword,
  ResetPassword,
} from "./pages/Authentication";
import VerifyEmail from "./pages/Authentication/VerifyEmail";
import Index from "./pages/Index";
import { Toaster, toast } from 'sonner'
import PrivateRoutes from "./components/PrivateRoutes";
function App() {
  return (
    <>
     <Toaster position="top-right" richColors closeButton />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/*" element={<Index />} />
          {/* <Route exact path="/onboarding" element={<Home />} /> */}
        </Route>

        {/* <Route path="/dashboard" element={<Index />} />
        <Route path="/enrichment" element={<Index />} />
        <Route path="/enrichment-details/*" element={<Index />} />
        <Route path="/data-lake" element={<Index />} />
        <Route path="/data-lake-detail/*" element={<Index />} />
        <Route path="/support" element={<Index />} />
        <Route path="/setting" element={<Index />} /> */}

        <Route path="/" element={<Navigate to="/signup" />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/signup" element={<Authentication />} />
        <Route path="/signin" element={<Authentication />} />
      </Routes>
    </>
  );
}

export default App;
