let details = [
  {
    label: "First Name",
    value: "first_name",
  },
  {
    label: "Last Name",
    value: "last_name",
  },
  {
    label: "Linkedin URL",
    value: "linkedin_url",
  },
  {
    label: "Organization Name",
    value: "organization_name",
  },
  {
    label: "Domain",
    value: "domain",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Country",
    value: "country",
  },
  {
    label: "State",
    value: "state",
  },
  {
    label: "Employee Count",
    value: "employee_count",
  },
  {
    label: "City",
    value: "city",
  },
  {
    label: "Linkedin Organization",
    value: "linkedin_org",
  },

  {
    label: "Title",
    value: "title",
  },
];

export default details;
