import { getGridStringOperators } from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";

const columns = [
  {
    field: "first_name",
    headerName: "First Name",
    type: "string",
    width: 120,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),
    renderCell: (params) => {
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {params.row?.final_data?.first_name || "-"}
        </div>
      );
    },
  },
  {
    field: "last_name",
    headerName: "Last Name",
    type: "string",
    width: 120,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value == ""
    ),

    renderCell: (params) => {
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {params.row?.final_data?.last_name || "-"}
        </div>
      );
    },
  },
  {
    field: "title",
    headerName: "Title",
    type: "string",
    width: 120,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),

    renderCell: (params) => {
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {params.row?.final_data?.title || "-"}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    type: "string",
    width: 220,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),

    renderCell: (params) => {
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {params.row?.final_data?.email || "-"}
        </div>
      );
    },
  },
  {
    field: "emailVerificationResult",
    headerName: "Email Validation Status",
    type: "string",
    width: 120,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),

    renderCell: (params) => {
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {params.row?.final_data?.emailVerificationResult || "-"}
        </div>
      );
    },
  },
  {
    field: "is_waterfall",
    headerName: "Is Waterfall",
    type: "string",
    width: 120,
    filterable: false,
    renderCell: (params) => {
      let is_waterfall = "-";
      if (params.row.final_data) {
        if (params.row.final_data.is_waterfall) {
          is_waterfall = "true";
        } else {
          is_waterfall = "false";
        }
      }
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {is_waterfall}
        </div>
      );
    },
  },
  {
    field: "linkedin_url",
    headerName: "LinkedIn Personal",
    type: "string",
    width: 220,
    filterable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),
    renderCell: (params) => {
      var url = params.row?.final_data?.linkedin_url || "-";

      if (url != "-") {
        return (
          <Tippy content={"Click to open"} arrow={true}>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate"
            >
              {url}
            </a>
          </Tippy>
        );
      } else {
        return (
          <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
            {url}
          </div>
        );
      }
    },
  },
  {
    field: "linkedin_org",
    headerName: "LinkedIn Organization",
    type: "string",
    width: 220,
    filterable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),
    renderCell: (params) => {
      var url = params.row?.final_data?.linkedin_org || "-";
      if (url == "-") {
        return (
          <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
            {url}
          </div>
        );
      }
      return (
        <Tippy content={"Click to open"} arrow={true}>
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate"
          >
            {url}
          </a>
        </Tippy>
      );
    },
  },
  {
    field: "organization_name",
    headerName: "Organization Name",
    type: "string",
    width: 120,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),

    renderCell: (params) => {
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {params.row?.final_data?.organization_name}
        </div>
      );
    },
  },
  {
    field: "domain",
    headerName: "Domain",
    type: "string",
    width: 220,
    filterable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),
    renderCell: (params) => {
      let url = params.row?.final_data?.domain || "-";

      if (url != "-") {
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
          url = "http://" + url;
        }
        return (
          <Tippy content={"Click to open"} arrow={true}>
            <a
              href={url}
              rel="external"
              target="_blank"
              className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate"
            >
              {url}
            </a>
          </Tippy>
        );
      } else {
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          -
        </div>;
      }
    },
  },
  {
    field: "employee_count",
    headerName: "Employee Count",
    type: "string",
    width: 120,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),

    renderCell: (params) => {
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {params.row?.final_data?.employee_count || "-"}
        </div>
      );
    },
  },

  {
    field: "city",
    headerName: "City",
    type: "string",
    width: 120,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),

    renderCell: (params) => {
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {params.row?.final_data?.city || "-"}
        </div>
      );
    },
  },
  {
    field: "state",
    headerName: "State",
    type: "string",
    width: 120,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),

    renderCell: (params) => {
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {params.row?.final_data?.state || "-"}
        </div>
      );
    },
  },
  {
    field: "country",
    headerName: "Country",
    type: "string",
    width: 120,
    filterable: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains"
    ),

    renderCell: (params) => {
      return (
        <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
          {params.row?.final_data?.country || "-"}
        </div>
      );
    },
  },
];

export default columns;
